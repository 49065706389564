import {
    GET_PAST_ORDER_DETAILS,
    GET_PAST_ORDER_DETAILS_LOADING
} from './types'
  
const initialState = {
    loading: false,
    orderDetail: {}
}
  
/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function(state = initialState, action) {
    switch (action.type) {
    case GET_PAST_ORDER_DETAILS_LOADING:
        return {
            ...state,
            loading: true,
            orderDetail: {}
    }
    case GET_PAST_ORDER_DETAILS:
        return {
            ...state,
            loading: false,
            orderDetail: action.payload
        }
    default:
        return state
    }
}