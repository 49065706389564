import {
    GET_SEARCH_DISCOUNTED_PRICE_INFO,
    GET_SEARCH_DISCOUNTED_PRICE_INFO_LOADING
} from './types'

const initialState = {
    loading: false,
    searchDiscountInfo: []
}

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function(state = initialState, action) {
    switch (action.type) {
    case GET_SEARCH_DISCOUNTED_PRICE_INFO_LOADING:
        return {
            ...state,
            loading: true,
            searchDiscountInfo: []
        }
    case GET_SEARCH_DISCOUNTED_PRICE_INFO:
        return {
            ...state,
            loading: false,
            searchDiscountInfo: action.payload
        }
    default:
        return state
    }
}