import { CCard, CCardBody} from '@coreui/react'
import React from 'react'
import LanguageSwitcher from 'src/common/LanguageSwitcher'
import './Maintenance.css'
import { Trans, useTranslation } from "react-i18next";

const Maintenance = () => {
    const { t } = useTranslation();

    return (
        <div className="ITJMaintenance__Container">
            <img
                src="/logo_itj.png"
                alt="Italjapan Logo"
                className="ITJMaintenance__Logo"
            />
            <CCard className="ITJMaintenance__Card">
                <CCardBody>
                    <span className="ITJMaintenance__Heading">
                        <Trans i18nKey="maintenance.heading"></Trans>
                    </span>
                    <p className="ITJMaintenance__Subheader">
                        <Trans i18nKey="maintenance.subtitle"></Trans>
                    </p>
                    <div className="ITJMaintenance__Language">
                        <LanguageSwitcher />
                    </div>
                </CCardBody>
            </CCard>
        </div>
    )
}

export default Maintenance
