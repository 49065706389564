import {
  SET_ASIDEBAR
} from './types'

const initialState = {
    asideShow: false,
}

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function(state = initialState, action) {
    switch (action.type) {
      case SET_ASIDEBAR:
        return {
          ...state,
          asideShow: action.payload
        }
      default:
        return state
    }
  }