import {
    GET_CAMPAIGN_ITEM,
    REMOVE_CAMPAIGN_ITEM
} from "./types";

export const registerCampaign = (data) => dispatch => {
    const decoded = JSON.stringify(data)
    localStorage.setItem("_campaign", decoded);
    dispatch(setCampaign(data));
}

export const setCampaign = (data) => {
    return{
        type: GET_CAMPAIGN_ITEM,
        payload: data
    }
}

export const unregisterCampaign = () => dispatch => {
    localStorage.removeItem("_campaign");
    dispatch(removeCampaign());
}

export const removeCampaign = () => {
    return{
        type: REMOVE_CAMPAIGN_ITEM
    }
}