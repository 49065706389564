import { GET_CART_ITEMS } from "./types";
import { database, firebaseAnalytics } from "src/common/Firebase";
import { setFirebaseWarningState } from "../firebaseWarning/actions";
import handleClientSideSentry from "src/common/handleClientSideSentry";

// import ReactPixel from 'react-facebook-pixel';

export const setCartItem = (uid, data) => (dispatch, getState) => {
  const existItem = getState().cart.cartInfo.find(
    (x) => x.product === data.product
  );

  // console.log('outside exist item', existItem);

  if (existItem) {
    existItem.product_qty += data.product_qty;
    existItem.product_amount = existItem.product_qty * existItem.product_price;

    database
      .ref(`cart/${uid}/${existItem.id}`)
      .update(existItem)
      .then(() => {
        dispatch(fetchCartItems(uid));
        // ReactPixel.track('AddToCart', {contents: [{id: existItem.product, quantity: existItem.product_quantity}]})
      })
      .catch((error) => {
        handleClientSideSentry(
          "setCartItem(existItem)",
          error,
          "/cart/actions.js"
        );
        if (error) {
          dispatch(setFirebaseWarningState());
        }
      });

    // console.log('exist item')
  } else {
    database
      .ref("cart/" + uid)
      .push(data)
      .then(() => {
        dispatch(fetchCartItems(uid));
        firebaseAnalytics.logEvent("add_to_cart", {
          email: getState().auth.user.email,
          product: data,
        });
        // ReactPixel.track('AddToCart', {contents: [{id: data.product, quantity: data.product_quantity}]})
      })
      .catch((error) => {
        handleClientSideSentry("setCartItem", error, "/cart/actions.js");
        if (error) {
          dispatch(setFirebaseWarningState());
        }
      });

    // console.log('pushed cart items')
  }
};

//Overwrites the cartitems array
export const setCartItems = (uid, key, cartItemData) => (dispatch) => {
  database
    .ref(`cart/${uid}/${key}`)
    .update(cartItemData)
    .then(() => dispatch(fetchCartItems(uid)))
    .catch((error) => {
      handleClientSideSentry("setCartItems", error, "/cart/actions.js");
      if (error) {
        dispatch(setFirebaseWarningState());
      }
    });
};

export const setActiveCartItems = (uid, activeCartData) => (dispatch) => {
  activeCartData.map((data) => {
    database
      .ref(`cart/${uid}`)
      .push(data)
      .then(() => dispatch(fetchCartItems(uid)))
      .catch((error) => {
        handleClientSideSentry("setActiveCartItems", error, "/cart/actions.js");
        if (error) {
          dispatch(setFirebaseWarningState());
        }
      });
  });
};

//Remove a particular cart item and update the redux state
export const removeCartItem = (uid, item) => (dispatch) => {
  database
    .ref(`cart/${uid}/${item.id}`)
    .remove()
    .then(() => dispatch(fetchCartItems(uid)))
    .catch((error) => {
      handleClientSideSentry("removeCartItem", error, "/cart/actions.js");
      if (error) {
        dispatch(setFirebaseWarningState());
      }
    });
};
//Remove all cart items based on rtdb and update the redux state
export const removeCartItems = (uid) => (dispatch) => {
  database
    .ref(`cart/${uid}`)
    .remove()
    .then(() => dispatch(fetchCartItems(uid)))
    .catch((error) => {
      handleClientSideSentry("removeCartItems", error, "/cart/actions.js");
      if (error) {
        dispatch(setFirebaseWarningState());
      }
    });
};
//Fetches all the cart items from the rtdb and updates the state
export const fetchCartItems = (uid) => async (dispatch) => {
  try {
    database.ref(`cart/${uid}`).on("value", (snapshot) => {
      const vals = snapshot.val();
      let _records = [];
      for (var key in vals) {
        _records.push({
          ...vals[key],
          id: key,
        });
      }
      dispatch({
        type: GET_CART_ITEMS,
        payload: _records,
      });
    });
  } catch (error) {
    handleClientSideSentry("fetchCartItems", error, "/cart/actions.js");
    if (error) {
      dispatch(setFirebaseWarningState());
    }
  }
};
