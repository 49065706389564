import { database } from "src/common/Firebase";
import { settingLanguage } from "src/feature/language/actions";
import axios from "axios";
import handleClientSideSentry from "src/common/handleClientSideSentry";
const rtdbStateSync = (
  userId,
  dispatch,
  i18n,
  setGlobalCurrency,
  completeAuthentication
) => {
  const dbRef = database.ref();
  dbRef
    .child("users")
    .child(userId)
    .get()
    .then(async (snapshot) => {
      if (snapshot.exists()) {
        completeAuthentication();
        //RTDB STATE SYNC FOR LANG
        const setLanguage = (state) => dispatch(settingLanguage(state));
        const changeLanguage = (lng) => {
          i18n.changeLanguage(lng);
          setLanguage(lng);
        };
        changeLanguage(snapshot.val().language);

        //RTDB STATE SYNC FOR CURRENCY
        const res = await axios.get(
          `https://api.frankfurter.app/latest?from=EUR`
        );
        const rate = res.data.rates[snapshot.val().currency];
        const data = {
          name: snapshot.val().currency,
          value: rate,
        };
        dispatch(setGlobalCurrency(data));
      } else {
        //Do nothing, let the defaults take over the app state
        //Doing nothing here is critical as Login.js is also searching
        //whether this user exists or not and if the user doesn't exist
        //then we log them out from firebase and throw an error.
        //on the login.js form
      }
    })
    .catch((error) => {
      handleClientSideSentry("rtdbStateSync", error, "rtdbStateSync.js");
    });
};
export default rtdbStateSync;
