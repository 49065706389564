import React, { useEffect, useState } from 'react'
import {
    CButton,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
  } from "@coreui/react";
import { useSelector, useDispatch} from 'react-redux';
import { useTranslation } from "react-i18next";
import { updateFirebaseWarningState } from 'src/feature/firebaseWarning/actions';
import { setLogout } from 'src/feature/auth/actions';

const FirebaseWarningModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const firebaseWarning = useSelector((state) => state.firebaseError.firebaseWarning);
    const [modal, setModal] = useState(false);

    const handleModalClick = () => {
        setModal(false)
        dispatch(setLogout());
        dispatch(updateFirebaseWarningState())
    }

    // Error Handler
    useEffect(() => {
        if(firebaseWarning === true) {
            setModal(true)
        }
    }, [firebaseWarning])
    
    return (
        <CModal show={modal} onClose={setModal} closeOnBackdrop={false} color="warning">
            <CModalHeader>
                <CModalTitle>
                    {
                        t('common.ERRHeader')
                    }
                </CModalTitle>
            </CModalHeader>
            <CModalBody>
                <p>
                    {
                        t('common.firebaseWarningBody')
                    }
                </p>
                <CButton color="warning" onClick={handleModalClick}>
                    OK
                </CButton>
            </CModalBody>
      </CModal>
    )
}

export default FirebaseWarningModal
