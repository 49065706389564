import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LanguageSwitcher from "src/common/LanguageSwitcher";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CBadge,
} from "@coreui/react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import CIcon from "@coreui/icons-react";
import { firebaseSignOut } from "../common/Firebase";
import { Trans, useTranslation } from "react-i18next";
// import { AuthContext } from "src/common/AuthProvider";
import { setLogout } from "src/feature/auth/actions";
import {
  setLoginPostAPI,
  setRtdbLanguage,
  setRtdbCurrency,
} from "src/feature/erpnextAPI/actions";
// import DarkMode from "src/common/DarkMode";
// import {setDarkMode} from 'src/feature/darkMode/actions'
import { settingLanguage } from "src/feature/language/actions";
import { getStaticWebPage } from "../feature/erpnextAPI/actions";
import { setGlobalCurrency } from "../feature/currencySelector/actions";
import { useHistory } from "react-router";

const TheHeaderDropdown = () => {
  const { i18n } = useTranslation();
  // const { currentUser } = useContext(AuthContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const auth = useSelector((state) => state.auth);
  const currencySelector = useSelector((state) => state.currencySelector);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const language = useSelector((state) => state.language.language);
  const staticWebsite = useSelector((state) => state.staticWebsite.staticPage);
  const customerInfo = useSelector(
    (state) => state.checkoutCustomerInfo.customerInfo
  );

  const [currencyCurrentValue, setCurrencyCurrentValue] = useState(0);
  const [currency, setCurrency] = useState([]);

  const setLanguage = (state) => dispatch(settingLanguage(state));

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  // useEffect(() => {
  //   dispatch(setRtdbLanguage(auth.user.email, "it"));
  // }, []);
  const updateRtdbLanguage = (lang) => {
    dispatch(setRtdbLanguage(auth.user.email, lang));
  };

  const updateRtdbCurrency = (symbol) => {
    dispatch(setRtdbCurrency(auth.user.email, symbol));
  };
  useEffect(() => {
    // The below condition is so that all non "it" languages get "en" based results for the links
    const data = {
      lang: language === "it" ? language : "en",
    };
    dispatch(getStaticWebPage(data));
  }, [dispatch, language]);

  const handleModalClick = () => {
    setModal(false);
    const selectedCurrency = currency.find(
      (i) => i[1] === Number(currencyCurrentValue)
    );
    const data = {
      name: selectedCurrency[0],
      value: selectedCurrency[1],
    };
    updateRtdbCurrency(data.name);
    dispatch(setGlobalCurrency(data));
  };

  const handleResetCurrency = () => {
    setModal(false);
    const data = {
      name: "EUR",
      value: 1.0,
    };
    updateRtdbCurrency(data.name);
    dispatch(setGlobalCurrency(data));
  };

  const handleLogout = (e) => {
    e.preventDefault();
    firebaseSignOut().then(() => {
      //dispatching Logout action and updating state to false
      //dispatching erpnext post api for logout update
      dispatch(setLogout());
      dispatch(setLoginPostAPI(auth.user.email, "Logged Out"));
    });
  };

  const handleCurrencySelector = () => {
    setModal(true);
  };

  useEffect(() => {
    if (currencySelector?.currency?.rates) {
      setCurrency(Object.entries(currencySelector?.currency?.rates));
    }
  }, [currencySelector?.currency?.rates]);

  // console.log(currencyCurrentValue)

  return (
    <>
      <CModal show={modal} onClose={setModal}>
        <CModalHeader>
          <CModalTitle>
            <Trans i18nKey="Header.15">Change Currency</Trans>
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div className="text-center">
            <div className="mt-1 ITJB2__CurrencySelector">
              <div className="ITJB2__CurrencySelector__Main">
                <div className="ITJB2__CurrencySelector__Main__Item">
                  <span>{currencySelector?.currency?.amount}</span>
                </div>
                <div className="ITJB2__CurrencySelector__Main__Item">
                  <span>{currencySelector?.currency?.base}</span>
                </div>
              </div>
              <div className="ITJB2__CurrencySelector__Main">
                <div className="ITJB2__CurrencySelector__Main__Item">
                  <span>{currencyCurrentValue}</span>
                </div>
                <div className="ITJB2__CurrencySelector__Main__Item__DropDown">
                  <select
                    value={currencyCurrentValue}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCurrencyCurrentValue(value);
                    }}
                  >
                    <option value={0}>**Select**</option>
                    {currency?.map((i) => (
                      <option key={i[0]} value={i[1]}>
                        {i[0]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <CButton
              color="primary"
              onClick={handleModalClick}
              className="mt-1"
              style={{
                width: "20%",
              }}
            >
              OK
            </CButton>
            <CButton
              color="primary"
              onClick={handleResetCurrency}
              className="mt-1 ml-2"
              style={{
                width: "20%",
              }}
            >
              RESET
            </CButton>
          </div>
        </CModalBody>
      </CModal>
      <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
        <CDropdownToggle className="c-header-nav-link" caret={false}>
          <div className="c-avatar avatar-circle avatar-circle-nav">
            <CIcon name="cil-user" size="lg" alt="User Image" />
          </div>
        </CDropdownToggle>
        <CDropdownMenu className="pt-0" placement="bottom-end">
          <CDropdownItem
            className={
              darkMode ? "DropDown__Break__DarkMode" : "DropDown__Break"
            }
          >
            <span>
              <Trans i18nKey="Header.14">MY ACCOUNT</Trans>
            </span>
          </CDropdownItem>
          <CDropdownItem
            onClick={() => history.push("/order")}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <AiOutlineShoppingCart className="DropDown__BredCrum__Icon" />
              <Trans i18nKey="Header.12">Orders</Trans>
            </div>
            <CBadge
              color="info"
              className="px-2"
              style={{
                padding: "3px 0px",
              }}
            >
              {`${customerInfo?.orders_placed}`}
            </CBadge>
          </CDropdownItem>
          <CDropdownItem onClick={() => history.push("/profile")}>
            <CIcon
              name="cil-user"
              alt="my-account"
              className="DropDown__BredCrum__Icon"
            />
            <Trans i18nKey="Header.11">Account Details</Trans>
          </CDropdownItem>
          <CDropdownItem
            onClick={() => window.open(`${staticWebsite[2]?.route}`, "_blank")}
          >
            <CIcon
              name="cilEnvelopeLetter"
              alt="Contact"
              className="DropDown__BredCrum__Icon"
            />
            <Trans i18nKey="Header.6">Contattaci</Trans>
          </CDropdownItem>
          <CDropdownItem
            onClick={() => window.open(`${staticWebsite[1]?.route}`, "_blank")}
          >
            <CIcon
              name="cilPhone"
              alt="Assistance"
              className="DropDown__BredCrum__Icon"
            />
            <Trans i18nKey="Header.7">Assistenza</Trans>
          </CDropdownItem>
          <CDropdownItem
            onClick={() => window.open(`${staticWebsite[0]?.route}`, "_blank")}
          >
            <CIcon
              name="cilUser"
              alt="Document"
              className="DropDown__BredCrum__Icon"
            />
            <Trans i18nKey="Header.8">Termini e Condizioni</Trans>
          </CDropdownItem>
          <CDropdownItem
            className={
              darkMode ? "DropDown__Break__DarkMode" : "DropDown__Break"
            }
          >
            <span>
              <Trans i18nKey="Header.13">SETTINGS</Trans>
            </span>
          </CDropdownItem>
          <CDropdownItem onClick={handleCurrencySelector}>
            <CIcon
              name="cil-dollar"
              alt="curency"
              className="DropDown__BredCrum__Icon"
            />
            <Trans i18nKey="Header.15">Change Currency</Trans>
          </CDropdownItem>
          {/* <CDropdownItem className="mfe-2" onClick={() => dispatch(setDarkMode(!darkMode))}>
          <DarkMode/>
          {
            darkMode ? 'Light' : 'Dark'
          }
        </CDropdownItem> */}
          <CDropdownItem
            className={
              darkMode ? "DropDown__Break__DarkMode" : "DropDown__Break"
            }
          >
            <span>
              <Trans i18nKey="Header.1"></Trans>
            </span>
          </CDropdownItem>
          <CDropdownItem
          // onClick={
          //   language === "en"
          //     ? () => changeLanguage("it")
          //     : () => changeLanguage("en")
          // }
          >
            <div>
              <LanguageSwitcher updateRtdbLanguage={updateRtdbLanguage} />
            </div>
          </CDropdownItem>
          <CDropdownItem divider />
          <CDropdownItem onClick={handleLogout}>
            <CIcon
              name="cil-lock-locked"
              className="DropDown__BredCrum__Icon"
            />
            <Trans i18nKey="Header.16">Logout</Trans>
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </>
  );
};

export default TheHeaderDropdown;
