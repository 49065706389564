import React from 'react'
import {
  TheContent
} from './index'

const TheLayout = () => {

  return (
    <TheContent/>
  )
}

export default TheLayout
