// import buildRequestFilter from "./buildRequestFilter";
import store from "src/store";
function buildFrom(current, resultsPerPage) {
  if (!current || !resultsPerPage) return;
  return (current - 1) * resultsPerPage;
}

function buildSort(sortDirection, sortField) {
  if (sortDirection && sortField) {
    return [{ [`${sortField}.keyword`]: sortDirection }];
  }
}

function buildMatch(searchTerm) {
  return searchTerm
    ? {
        multi_match: {
          query: searchTerm,
          fields: [
            "Item Name.edge_ngram",
            "Item Code.edge_ngram",
            "Brand.edge_ngram",
            "Item Group.Item Group 1.edge_ngram",
          ],
          operator: "AND",
        },
      }
    : { match_all: {} };
}

function buildRequestFilter(sidebarItemGroups, sidebarParentItemGroups) {
  return [
    {
      terms: {
        "Item Group.Item Group 2.keyword": sidebarItemGroups,
      },
    },
    {
      terms: {
        "Item Code.keyword": sidebarParentItemGroups,
      },
    },
  ];
}

export default function buildRequest(state) {
  // Language taken directly from redux store rather than local storage
  let language = store.getState().language.language;
  // console.log("build req", language);
  // let language =
  //   localStorage.getItem("language") &&
  //   JSON.parse(localStorage.getItem("language"));

  const {
    current,
    resultsPerPage,
    searchTerm,
    sidebarItemGroups,
    sidebarParentItemGroups,
    sortDirection,
    sortField,
  } = state;

  // console.log('sidebarParentItemGroups', sidebarParentItemGroups)
  const sort = buildSort(sortDirection, sortField);
  const match = buildMatch(searchTerm);
  const size = resultsPerPage;
  const from = buildFrom(current, resultsPerPage);
  const filter = buildRequestFilter(sidebarItemGroups, sidebarParentItemGroups);

  // console.log('sidebarItemGroups Cerca', sidebarItemGroups)

  const body = {
    // Static query Configuration
    // --------------------------
    // https://www.elastic.co/guide/en/elasticsearch/reference/7.x/search-request-highlighting.html
    highlight: {
      fragment_size: 200,
      number_of_fragments: 1,
      fields: {
        "Item Name": {},
        "Item Code": {},
        Brand: {},
        "PIM Watch Info.Item Image": {},
      },
    },
    //https://www.elastic.co/guide/en/elasticsearch/reference/7.x/search-request-source-filtering.html#search-request-source-filtering
    _source: [
      "Item Name",
      "Item Code",
      "Brand",
      "Standard Selling Rate",
      "PIM Watch Info.Item Image",
      "Item Group",
      "Item Group.Item Group 1",
      "Item Image",
    ],
    query: {
      bool: {
        must: [match],
        ...(filter && { filter }),
      },
    },
    // https://www.elastic.co/guide/en/elasticsearch/reference/7.x/search-request-sort.html
    ...(sort && { sort }),
    // https://www.elastic.co/guide/en/elasticsearch/reference/7.x/search-request-from-size.html
    ...(size && { size }),
    ...(from && { from }),
    //Timestamp of YYMMDD will be appended by the firebase function (esSearchItems)
    indexName: language != "en" ? `items-${language}` : `items`,
  };
  // console.log("language es", language != "en" ? `items-${language}` : `items`);
  // console.log('Cerca Body', body)
  return body;
}
