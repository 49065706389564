import {
    GET_SHOPPING_CART_RULE_LOADING,
    GET_SHOPPING_CART_RULE
  } from './types'
  
  const initialState = {
      loading: false,
      shoppingCartRule: {}
  }
  
  /* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
  export default function(state = initialState, action) {
      switch (action.type) {
        case GET_SHOPPING_CART_RULE_LOADING:
          return {
            ...state,
            loading: true,
            shoppingCartRule: {}
          }
        case GET_SHOPPING_CART_RULE:
          return {
            ...state,
            loading: false,
            shoppingCartRule: action.payload
          }
        default:
          return state
      }
    }