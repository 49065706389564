import React from 'react'
import './styles.scss'

const Index = () => {
    return (
        <div>
            <div className="loader">
                <div className="skeleton circle"></div>
                <div className="skeleton-group">
                    <div className="skeleton block"></div>
                    <div className="skeleton block small"></div>
                </div>
            </div>
            <div className="loader">
                <div className="skeleton circle"></div>
                <div className="skeleton-group">
                    <div className="skeleton block"></div>
                    <div className="skeleton block small"></div>
                </div>
            </div>
            <div className="loader">
                <div className="skeleton circle"></div>
                <div className="skeleton-group">
                    <div className="skeleton block"></div>
                    <div className="skeleton block small"></div>
                </div>
            </div>
            <div className="loader">
                <div className="skeleton circle"></div>
                <div className="skeleton-group">
                    <div className="skeleton block"></div>
                    <div className="skeleton block small"></div>
                </div>
            </div>
            <div className="loader">
                <div className="skeleton circle"></div>
                <div className="skeleton-group">
                    <div className="skeleton block"></div>
                    <div className="skeleton block small"></div>
                </div>
            </div>
        </div>
    )
}

export default Index
