import React, { useEffect, useState } from 'react'
import {
    CButton,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
  } from "@coreui/react";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const ErrModule = () => {
    const { t } = useTranslation();
    const error = useSelector((state) => state.error);
    const timeoutErr = useSelector((state) => state.timeoutError.timeout);
    const [modal, setModal] = useState(false);
    const [modalBody, setModalBody] = useState('');

    const handleModalClick = () => {
        setModal(false)
    }

    // Error Handler

    useEffect(() => {
        if(timeoutErr !== null && timeoutErr?.includes('timeout') === true) {
            setModal(true)
            setModalBody(`${t('common.timeoutError')}`)
        } else if(Object.keys(error).length > 0) {
            setModal(true)
            setModalBody(`${t('common.commonError')}`)
        }
    }, [error])
    
    return (
        <CModal show={modal} onClose={setModal}>
            <CModalHeader>
                <CModalTitle>
                    {
                        t('common.ERRHeader')
                    }
                </CModalTitle>
            </CModalHeader>
            <CModalBody>
                <p>
                    {
                        modalBody
                    }
                </p>
                <CButton color="primary" onClick={handleModalClick}>
                    OK
                </CButton>
            </CModalBody>
      </CModal>
    )
}

export default ErrModule
