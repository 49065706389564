import {
    GET_CAMPAIGN_ITEM,
    REMOVE_CAMPAIGN_ITEM
} from "./types";

const initialState = {
    campaignData : null
}

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function(state = initialState, action)
{
    switch(action.type)
    {
        case GET_CAMPAIGN_ITEM:
            return {
                ...state,
                campaignData: action.payload
            }
        case REMOVE_CAMPAIGN_ITEM:
            return {
                ...state,
                campaignData: null
            }
        default:
            return state;
    }
}