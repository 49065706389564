import {
    GET_MAINTENANCE_MODE,
    GET_MAINTENANCE_LOADING,
    GET_MAINTENANCE_USERS
} from './types'

const initialState = {
    loading: false,
    mode: null,
    maintenance_users: null
}

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function(state = initialState, action) {
    switch (action.type) {
    case GET_MAINTENANCE_LOADING:
        return {
            ...state,
            loading: true,
            mode: null,
            maintenance_users: null
        }
    case GET_MAINTENANCE_MODE:
        return {
            ...state,
            loading: false,
            mode: action.payload
        }
    case GET_MAINTENANCE_USERS:
        return {
            ...state,
            loading: false,
            maintenance_users: action.payload
        }
    default:
        return state
    }
}