import {
    UPDATE_CUSTOMER_PROFILE,
    UPDATE_CUSTOMER_PROFILE_LOADING,
    REMOVE_CUSTOMER_PROFILE
} from './types'
  
const initialState = {
    loading: false,
    message: ''
}
  
/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function(state = initialState, action) {
    switch (action.type) {
    case UPDATE_CUSTOMER_PROFILE_LOADING:
        return {
            ...state,
            loading: true,
            message: ''
        }
    case UPDATE_CUSTOMER_PROFILE:
        return {
            ...state,
            loading: false,
            message: action.payload    
        }
    case REMOVE_CUSTOMER_PROFILE:
        return {
            ...state,
            loading: false,
            message: ''
        }
    default:
        return state
    }
}