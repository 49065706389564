import React , {useRef} from 'react'
import IdelTimer from 'react-idle-timer'
import { useDispatch, useSelector } from 'react-redux';
import { firebaseSignOut } from 'src/common/Firebase';
import { setLogout } from 'src/feature/auth/actions';
import { setLoginPostAPI } from 'src/feature/erpnextAPI/actions';

const IdelTimeContainer = () => {

    const dispatch = useDispatch();
    const idelTimerRef = useRef(null)
    const auth = useSelector(state => state.auth);

    const handleOnIdle = () => {
        // console.log('idel Timeout Logged Out');
        firebaseSignOut()
            .then(() => {
                dispatch(setLogout());
                dispatch(setLoginPostAPI(auth.user.email, 'Logged Out Inactivity')) 
            });
    }

    return (
        <>
            <IdelTimer 
                ref={idelTimerRef}
                timeout={1000 * 60 * 60}
                // timeout={10000}
                onIdle={handleOnIdle}
            />
        </>
    )
}

export default IdelTimeContainer
