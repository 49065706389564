// import axios from 'axios';
import * as Sentry from "@sentry/react";

import {
  GET_SHOPPING_CART_RULE_LOADING,
  GET_SHOPPING_CART_RULE,
} from "./types";

import {
  GET_STATIC_WEB_PAGE,
  GET_STATIC_WEB_PAGE_LOADING,
} from "./StaticWebPage/types";

import { GET_ERROR } from "../error/types";

import {
  GET_PRODUCT_PAGE_INFO,
  GET_PRODUCT_PAGE_INFO_LOADING,
} from "./ProductInfo/types";

import {
  GET_DISCOUNTED_PRICE_INFO,
  GET_DISCOUNTED_PRICE_INFO_LOADING,
} from "./DiscountedPriceAPI/types";

import {
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_LOADING,
} from "./GetCountryAPI/types";

import {
  GET_CUSTOMER_INFO,
  GET_CUSTOMER_INFO_LOADING,
} from "./CustomerInfoAPI/types";

import {
  REMOVE_CHECKOUT_ORDER_DETAILS,
  SET_CHECKOUT_ORDER,
  SET_CHECKOUT_ORDER_LOADING,
} from "./CheckoutAPI/types";

import {
  SET_UPDATE_CART_ORDER_SUMMERY,
  SET_UPDATE_CART_ORDER_SUMMERY_LOADING,
} from "./UpdateCartAPI/types";

import {
  GET_ARRIVALS_DETAILS,
  GET_ARRIVALS_DETAILS_LOADING,
} from "./NewArrivalsAPI/types";

import {
  UPDATE_ORDER_BY_AVAILABILITY,
  UPDATE_ORDER_BY_AVAILABILITY_LOADING,
} from "./UpdateOrderByAvailability/types";

import {
  GET_ACTIVE_ORDER,
  GET_ACTIVE_ORDER_LOADING,
} from "./ActiveOrderAPI/types";

import { GET_PAST_ORDER, GET_PAST_ORDER_LOADING } from "./OrderAPI/types";

import {
  GET_PAST_ORDER_DETAILS,
  GET_PAST_ORDER_DETAILS_LOADING,
} from "./OrderDetailsAPI/types";

import {
  GET_SEARCH_DISCOUNTED_PRICE_INFO,
  GET_SEARCH_DISCOUNTED_PRICE_INFO_LOADING,
} from "./SearchDiscountedPriceAPI/types";

import {
  REMOVE_CUSTOMER_PROFILE,
  UPDATE_CUSTOMER_PROFILE,
  UPDATE_CUSTOMER_PROFILE_LOADING,
} from "./UpdateProfile/types";

import {
  GET_LIST_OF_OUTLET_ITEMS,
  GET_LIST_OF_OUTLET_ITEMS_LOADING,
} from "./ListOfOutelet/types";

import {
  updateLeadActivity,
  updateRtdbLanguage,
  shoppingCartRules,
  staticWebPage,
  getBootInfo,
  productPageInfo,
  discountedPriceInfo,
  searchDiscountedPriceInfo,
  fetchSearchDiscountedPrice,
  customerInfo,
  updateCart,
  orderCheckout,
  newArrivalsDetails,
  updateOrderAvailability,
  activeOrder,
  pastOrder,
  pastOrderDetails,
  orderByCountry,
  updateCustomerProfile,
  listOfOuteltItems,
  announcementText,
  database,
  updateRtdbCurrency,
  getCustomerInfoRTDB,
} from "src/common/Firebase";

import {
  GET_ANNOUNCEMENT,
  GET_ANNOUNCEMENT_LOADING,
} from "./AnnouncementAPI/types";

import {
  GET_MAINTENANCE_USERS,
  GET_MAINTENANCE_MODE,
  GET_MAINTENANCE_LOADING,
} from "./MaintenanceAPI/types";

import {
  GET_DASHBOARD_INFO,
  GET_DASHBOARD_INFO_LOADING,
} from "./DashboardInfoAPI/types";

import { GET_TIMEOUT_ERROR } from "../timeoutError/types";
import { setFirebaseWarningState } from "../firebaseWarning/actions";

const handleError = async (error, dispatch) => {
  if (error.code === "aborted") {
    dispatch({
      type: GET_TIMEOUT_ERROR,
      payload: error,
    });
  } else {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
  Sentry.captureException(error);
};

// Updates the lead activity on ERPnext on a table.
// Updates logins and logouts on the ERPnext table
export const setLoginPostAPI =
  (lead_email, description) => async (dispatch) => {
    try {
      await updateLeadActivity({ lead_email, description });
    } catch (error) {
      await handleError(error, dispatch);
    }
  };

export const setRtdbLanguage = (lead_email, language) => async (dispatch) => {
  try {
    await updateRtdbLanguage({ lead_email, language });
  } catch (error) {
    await handleError(error, dispatch);
  }
};

export const setRtdbCurrency = (lead_email, currency) => async (dispatch) => {
  try {
    await updateRtdbCurrency({ lead_email, currency });
  } catch (error) {
    await handleError(error, dispatch);
  }
};

export const getShoppingCartRule = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SHOPPING_CART_RULE_LOADING,
    });

    let res = await shoppingCartRules(data);

    dispatch({
      type: GET_SHOPPING_CART_RULE,
      payload: res.data.message,
    });
  } catch (error) {
    await handleError(error, dispatch);
  }
};

export const getStaticWebPage = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_STATIC_WEB_PAGE_LOADING,
    });

    staticWebPage(data).then((res) => {
      dispatch({
        type: GET_STATIC_WEB_PAGE,
        payload: res.data.message,
      });
    });
  } catch (error) {
    await handleError(error, dispatch);
  }
};

export const getDashboardInfo = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DASHBOARD_INFO_LOADING,
    });
    let res = await getBootInfo(data);

    // issue-244: Reorder sidebar items: Show clocks, jewellery, then watches.
    // let sidebar = res.data.message?.sidebar;
    // if (sidebar) {
    //     console.log('SIDEBAR CHILDREN', sidebar.children)
    //     let sidebar_children = [];

    //     let top_level_children = ['Clocks', 'Jewellery', 'Watches'];

    //     for(let i=0; i<=top_level_children.length-1; i++) {
    //         let top_level_child = sidebar.children.find((c) => (c.name === top_level_children[i]))
    //         if (top_level_child) {
    //             sidebar_children.push(top_level_child)
    //         }
    //     }

    //     res.data.message.sidebar.children = sidebar_children;
    // }
    // Rolled back sorting: It will be managed on the backend.

    dispatch({
      type: GET_DASHBOARD_INFO,
      payload: res.data.message,
    });
  } catch (error) {
    await handleError(error, dispatch);
  }
};

export const getProductPageInfo = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PRODUCT_PAGE_INFO_LOADING,
    });

    productPageInfo(data).then((res) => {
      dispatch({
        type: GET_PRODUCT_PAGE_INFO,
        payload: res.data.message,
      });
    });
  } catch (error) {
    await handleError(error, dispatch);
  }
};

export const getDiscountedPriceInfo = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DISCOUNTED_PRICE_INFO_LOADING,
    });

    discountedPriceInfo(data).then((res) => {
      dispatch({
        type: GET_DISCOUNTED_PRICE_INFO,
        payload: res.data.message,
      });
    });
  } catch (error) {
    await handleError(error, dispatch);
  }
};
export const getSearchDiscountedPriceInfo = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SEARCH_DISCOUNTED_PRICE_INFO_LOADING,
    });

    searchDiscountedPriceInfo(data).then((res) => {
      dispatch({
        type: GET_SEARCH_DISCOUNTED_PRICE_INFO,
        payload: res.data.message,
      });
    });
  } catch (error) {
    await handleError(error, dispatch);
  }
};

export const fetchSearchDiscountedPriceInfo = async (data) => {
  try {
    const res = await fetchSearchDiscountedPrice(data);
    return res.data.message;
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const getCountryList = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_COUNTRY_LIST_LOADING,
    });

    database.ref("settings/country_list").on("value", (snapshot) => {
      const vals = snapshot.val();
      let _records = [];
      for (var key in vals) {
        _records.push({
          id: key,
          value: vals[key],
        });
      }
      dispatch({
        type: GET_COUNTRY_LIST,
        payload: _records,
      });
    });
  } catch (error) {
    if (error) {
      dispatch(setFirebaseWarningState());
    }
  }
};

export const getCustomerInfo = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CUSTOMER_INFO_LOADING,
    });

    let res = await getCustomerInfoRTDB();
    dispatch({
      type: GET_CUSTOMER_INFO,
      payload: res.val(),
    });
  } catch (error) {
    await handleError(error, dispatch);
  }
};

export const setCartProductUpdate = (data, history) => async (dispatch) => {
  try {
    dispatch({
      type: SET_UPDATE_CART_ORDER_SUMMERY_LOADING,
    });

    updateCart(data).then((res) => {
      dispatch({
        type: SET_UPDATE_CART_ORDER_SUMMERY,
        payload: res.data.message,
      });
      history.push("/cassa");
    });
  } catch (error) {
    await handleError(error, dispatch);
  }
};

export const checkoutOrder = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_CHECKOUT_ORDER_LOADING,
    });

    const res = await orderCheckout(data);

    dispatch({
      type: SET_CHECKOUT_ORDER,
      payload: res.data.message,
    });
  } catch (error) {
    await handleError(error, dispatch);
  }
};

export const removeCheckoutOrderDetails = () => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_CHECKOUT_ORDER_DETAILS,
    });
  } catch (error) {
    await handleError(error, dispatch);
  }
};

// TODO: Deprecate this, reducer and state.
export const updateOrderByAvailability = (data) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_ORDER_BY_AVAILABILITY_LOADING,
    });

    updateOrderAvailability(data).then((res) => {
      dispatch({
        type: UPDATE_ORDER_BY_AVAILABILITY,
        payload: res.data.message,
      });
    });
  } catch (error) {
    await handleError(error, dispatch);
  }
};

// TODO: Deprecate this, reducer and state.
export const getActiveOrder = (data, getState) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ACTIVE_ORDER_LOADING,
    });

    let res = await activeOrder(data);

    dispatch({
      type: GET_ACTIVE_ORDER,
      payload: res.data.message,
    });
  } catch (error) {
    await handleError(error, dispatch);
  }
};

export const getPastOrder = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PAST_ORDER_LOADING,
    });

    const res = await pastOrder(data);

    dispatch({
      type: GET_PAST_ORDER,
      payload: res.data.message,
    });
  } catch (error) {
    await handleError(error, dispatch);
  }
};

export const getPastOrderDetails = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PAST_ORDER_DETAILS_LOADING,
    });

    const res = await pastOrderDetails(data);

    dispatch({
      type: GET_PAST_ORDER_DETAILS,
      payload: res.data.message,
    });
  } catch (error) {
    await handleError(error, dispatch);
  }
};

export const setOrderByCountry = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_UPDATE_CART_ORDER_SUMMERY_LOADING,
    });

    const res = await orderByCountry(data);
    dispatch({
      type: SET_UPDATE_CART_ORDER_SUMMERY,
      payload: res.data.message,
    });
  } catch (error) {
    await handleError(error, dispatch);
  }
};

export const setCustomerProfileUpdate = (data) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_CUSTOMER_PROFILE_LOADING,
    });

    updateCustomerProfile(data).then((res) => {
      dispatch({
        type: UPDATE_CUSTOMER_PROFILE,
        payload: res.data.message === "Profile Updated" ? "success" : "failure",
      });
    });
  } catch (error) {
    await handleError(error, dispatch);
  }
};

export const removeCustomerProfileUpdateDetails = () => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_CUSTOMER_PROFILE,
    });
  } catch (error) {
    await handleError(error, dispatch);
  }
};

export const getAnnouncementText = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ANNOUNCEMENT_LOADING,
    });

    announcementText().then((res) => {
      dispatch({
        type: GET_ANNOUNCEMENT,
        payload: res.data.message,
      });
    });
  } catch (error) {
    await handleError(error, dispatch);
  }
};

export const getMaintenanceMode = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_MAINTENANCE_LOADING,
    });

    database.ref(`settings/maintenance_mode`).on("value", (snapshot) => {
      const vals = snapshot.val();
      dispatch({
        type: GET_MAINTENANCE_MODE,
        payload: vals,
      });
    });

    database.ref(`settings/maintenance_users`).on("value", (snapshot) => {
      const vals = snapshot.val();
      let _records = [];
      for (var key in vals) {
        _records.push({
          ...vals[key],
          id: key,
        });
      }
      dispatch({
        type: GET_MAINTENANCE_USERS,
        payload: vals,
      });
    });
  } catch (error) {
    if (error) {
      dispatch(setFirebaseWarningState());
    }
  }
};
