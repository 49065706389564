import {
    CLEAR_ACTIVE_ORDER,
    GET_ACTIVE_ORDER,
    GET_ACTIVE_ORDER_LOADING
} from "./types";

const initialState = {
    loading: false,
    active_order: {},
}

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function(state = initialState, action)
{
    switch(action.type)
    {
        case GET_ACTIVE_ORDER_LOADING:
            return {
                ...state,
                loading: true,
                active_order: {}
            }
        case GET_ACTIVE_ORDER:
            return {
                ...state,
                loading: false,
                active_order: action.payload
            }
        case CLEAR_ACTIVE_ORDER:
            return {
                ...state,
                loading: false,
                active_order: {}
            }
        default:
            return state;
    }
}