import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaAngleRight } from "react-icons/fa";
import { VscFilePdf } from "react-icons/vsc";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import { Trans, useTranslation } from "react-i18next";
import classNames from "classnames";
import { CSidebar, CSidebarBrand, CSidebarNav, CBadge } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import ITJSidebarLogo from "src/assets/svg_icons/ITJSidebarLogo.svg";
import { setSideBar } from "src/feature/sidebar/actions";
import {
  getCustomerInfo,
  getDashboardInfo,
} from "src/feature/erpnextAPI/actions";
import SidebarLoading from "src/views/Loading/Sidebar/index";
import { useHistory } from "react-router";
import { fetchCartItems } from "src/feature/cart/actions";

const TheSidebar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const show = useSelector((state) => state.sidebarShow);
  const darkMode = useSelector((state) => state.darkMode);
  const dashboard = useSelector((state) => state.dashboard);
  const { loading, dashboardInfo } = dashboard;
  const [showNav, setShowNav] = useState([]);
  const [showNav2, setShowNav2] = useState([]);
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const language = useSelector((state) => state.language.language);
  const customerInfo = useSelector(
    (state) => state.checkoutCustomerInfo.customerInfo
  );

  const getCustomerCountry = () => {
    return customerInfo.territory;
  };

  const dashboardInfoParams = {
    lead_email: auth.user.email,
    lang: getCustomerCountry() === "Italy" ? "it" : "en",
  };

  useEffect(() => {
    if (!Object.keys(dashboardInfo || {}).length) {
      dispatch(getDashboardInfo(dashboardInfoParams));
    }
  }, [dispatch, auth, language]);

  useEffect(() => {
    const sidebarData = {
      lead_email: auth.user.email, //venus@maroon.com
      lang: "en",
    };
    const data = {
      lead_email: auth.user.email,
    };
    dispatch(fetchCartItems(auth.user.uid));
    dispatch(getCustomerInfo(data));
    //dispatch(getDashboardInfo(sidebarData)) DO NOT FETCH HERE. FETCH DIRECTLY ON THRE DASHBOARD.
  }, [dispatch, auth]);

  const toggleDropdown = (id) => {
    const shownState = showNav.slice();
    shownState.push(id);
    setShowNav(shownState);
  };

  const toggleDropdown2 = (id) => {
    const shownState = showNav2.slice();

    const index = shownState.indexOf(id);

    if (index >= 0) {
      shownState.splice(index, 1);
      setShowNav2(shownState);
    } else {
      shownState.push(id);
      setShowNav2(shownState);
    }
  };

  const onSubmit = (children, name, parentName, clickedItemGroup) => {
    // console.log('CIG', clickedItemGroup)
    if (children.length <= 0) {
      history.push(`/${parentName}/${name}`);
      // console.log('onSubmit', name, parentName)
    } else {
      history.push(`/${parentName}/${name}`);
    }
    dispatch(setSideBar("responsive"));
  };

  const onMainSectionClick = (name) => {
    if (name) {
      const orologiName = showNav2.filter((i) => i === name);
      if (!orologiName.includes(name)) {
        history.push(`/${name}`);
        dispatch(setSideBar("responsive"));
        setShowNav([]);
      }
    }
  };

  const lastSectionHandler = (parentName, childName, currentName) => {
    if (parentName) {
      history.push(`/${parentName}/${childName}/${currentName}`);
      dispatch(setSideBar("responsive"));
    }
  };

  return (
    <>
      <CSidebar
        show={show.sidebarShow}
        unfoldable
        onShowChange={(val) => dispatch(setSideBar(val))}
      >
        <CSidebarBrand
          className="d-md-down-none"
          to="/"
          style={{ minHeight: 63.5 }}
        >
          <img
            src={ITJSidebarLogo}
            alt="Italjapan Logo"
            style={{
              height: 40,
            }}
          />
          <CIcon
            className="c-sidebar-brand-minimized"
            name="sygnet"
            height={35}
          />
        </CSidebarBrand>
        <CSidebarNav>
          {loading ? (
            <SidebarLoading />
          ) : (            
            dashboardInfo?.sidebar?.children?.map((el, index) => {
              return (<div key={el.name}>
                <div
                  className={
                    darkMode.darkMode
                      ? "ITJSidebar__Header__darkMode"
                      : "ITJSidebar__Header"
                  }
                  onClick={() => toggleDropdown2(el.name)}
                >
                  <div
                    className="ITJSidebar__Header__Link"
                    onClick={() => onMainSectionClick(el.name)}
                  >
                    <div className="ITJSidebar__Header__Link__Body">
                      <h2 className="ITJSidebar__Header__Name">
                        {t(`Sidebar.${el.name}`)}
                      </h2>
                      {showNav2.includes(el.name) ? (
                        <MdKeyboardArrowDown
                          style={{
                            marginRight: "13px",
                          }}
                        />
                      ) : (
                        <MdKeyboardArrowRight
                          style={{
                            marginRight: "13px",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="ITJSidebar__Container">
                  {showNav2.includes(el.name) &&
                    el.children.map((el2) => (
                      <div
                        onClick={() => toggleDropdown(el2.name)}
                        key={el2.name}
                      >
                        {el2.available_items_count !== 0 && (
                          <div
                            // onClick={() => onSubmit(el2.children, el2.name, el.name)}
                            className="ITJSidebar"
                          >
                            <VscFilePdf
                              onClick={() =>
                                el2.pdf_url !== null &&
                                window.open(`${el2.pdf_url}`, "_blank")
                              }
                              className={classNames(
                                "ITJSidebarNavIcon",
                                el2.pdf_url === null &&
                                  "ITJB2SidebarNavIcon_Pdf"
                              )}
                            />
                            <div
                              onClick={() =>
                                onSubmit(el2.children, el2.name, el.name, el2)
                              }
                              className="ITJSidebar__Items"
                            >
                              <h2 className="ITJSidebar__Name">{el2.name}</h2>
                              {el2.discount_percentage != null && (
                                <small>
                                  {t("Sidebar.5")} {el2.discount_percentage}%
                                </small>
                              )}
                            </div>
                            {el2.available_items_count !== 0 && (
                              <CBadge
                                color="light"
                                className="mfs-auto mr-2 ITJSidebar__badge"
                              >
                                {el2.available_items_count}
                              </CBadge>
                            )}
                          </div>
                        )}
                        {showNav.includes(el2.name) &&
                          el2.children.map(
                            (item, i) =>
                              item.available_items_count !== 0 && (
                                <div
                                  key={item.name}
                                  className={
                                    darkMode.darkMode
                                      ? "ITJSidebarDropDown__darkMode"
                                      : "ITJSidebarDropDown"
                                  }
                                  onClick={() =>
                                    lastSectionHandler(
                                      el.name,
                                      el2.name,
                                      item.name
                                    )
                                  }
                                  // to={
                                  //   el.name && `/${el.name}/${el2.name}/${item.name}`
                                  // }
                                >
                                  <div className="ITJSidebarDropDown__Item">
                                    <FaAngleRight className="ITJSidebarDropDown__icon__item" />
                                    <h2>{item.name}</h2>
                                  </div>
                                  <CBadge
                                    color="light"
                                    className="ITJSidebarDropDown__badge__item"
                                  >
                                    {item.available_items_count}
                                  </CBadge>
                                </div>
                              )
                          )}
                      </div>
                    ))}
                </div>
              </div>)
            })
          )}
          <div className="ITJSidebar__hotButton__group">
            <div
              className="ITJSidebar__hotButton"
              onClick={() => history.push("/Watches?_sort=Last Arrival")}
            >
              <CBadge
                color="info"
                className="mr-2 ITJSidebar__hotButton__badge"
              >
                NEW
              </CBadge>
              <h2>
                <Trans i18nKey="Sidebar.1">Ultimi arrivi</Trans>
              </h2>
            </div>
            <div
              className="ITJSidebar__hotButton"
              onClick={() => history.push("/Watches?_sort=Popularity")}
            >
              <CBadge
                color="success"
                className="mr-2 ITJSidebar__hotButton__badge"
              >
                BEST
              </CBadge>
              <h2>
                <Trans i18nKey="Sidebar.2">Pi'u Venduti</Trans>
              </h2>
            </div>
            <div
              className="ITJSidebar__hotButton"
              onClick={() => history.push("/Watches?is_outlet=True")}
            >
              <CBadge
                color="danger"
                className="mr-2 ITJSidebar__hotButton__badge"
              >
                SALE
              </CBadge>
              <h2>
                <Trans i18nKey="Sidebar.3">Outlet</Trans>
              </h2>
            </div>
          </div>
        </CSidebarNav>
      </CSidebar>
    </>
  );
};

export default React.memo(TheSidebar);
