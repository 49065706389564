import React from 'react'
import { useSelector } from 'react-redux';
import './NotificationStyles.css'
// import { FaUmbrellaBeach } from "react-icons/fa";

const NotificationBand = () => {
    const announcementNotifications = useSelector((state) => state.announcementNotifications);

    const {
        loading,
        message
    } = announcementNotifications

    return (
        <div className="notification__band">
            {/* <FaUmbrellaBeach /> */}
            {
               loading ? 'Loading...' : message?.announcement_text
            }
        </div>
    )
}

export default NotificationBand
