import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CSubheader,
  CBreadcrumbRouter,
  CProgress
} from "@coreui/react";
import Cart from 'src/assets/svg_icons/Cart';
import {
  getShoppingCartRule
} from "../feature/erpnextAPI/actions";
import classNames from 'classnames'
import ITJLogo from 'src/assets/svg_icons/ITJLogo.svg'

// routes config
import routes from "../routes";

import { TheHeaderDropdown } from "./index";

import {setSideBar} from 'src/feature/sidebar/actions'

import Cerca from "src/common/Cerca";
import MobileCerca from "src/common/MobileCerca";
import CurrencyConversion from "src/utils/CurrencyConversion";

const useWindowSize = () => {
  const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
  useEffect(() => {
      const handleResize = () => {
          setSize([window.innerHeight, window.innerWidth])
      }
      window.addEventListener("resize", handleResize);
      return () => {
          window.removeEventListener("resize", handleResize)
      }
  }, [])
  return size
}

const TheHeader = () => {
  const { t } = useTranslation();

  const cartItems = useSelector(state => state.cart.cartInfo);

  //TODO: Fix qty calculation
  const totalQty = cartItems.reduce(function (sum, item) {
    return sum + item.product_qty;
  }, 0);
  const totalAmount = cartItems.reduce(function (sum, item) {
    return sum + item.product_amount;
  }, 0);

  const history = useHistory();
  const [height, width] = useWindowSize();
  // const { t } = useTranslation();

  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const shoppingCartRule = useSelector(state => state.shoppingCartRule);
  const sidebarShow = useSelector((state) => state.sidebarShow);
  const customerInfo = useSelector((state) => state.checkoutCustomerInfo.customerInfo);

  // Dispatching Shopping Cart Rule API

  useEffect(() => {
    const data = {
      lead_email: auth.user.email, 
      cart_amount: totalAmount
    }
    dispatch(getShoppingCartRule(data));
  }, [dispatch, auth.user.email, totalAmount])

  const toggleSidebar = () => {
    const val = [true, "responsive"].includes(sidebarShow.sidebarShow)
      ? false
      : "responsive";
    dispatch(setSideBar(val));
  };

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow.sidebarShow)
      ? true
      : "responsive";
    dispatch(setSideBar(val));
  };

  const mobileHeader = () => (
      <div className="Header__Optional">

        <div className="Header__Optional__first">
          
          <CHeaderNav className="Header__Nav">
            <MobileCerca />
          </CHeaderNav>
          {
            customerInfo.sales_partner === "B2B BASE" ? 
            <CHeaderNav className="Header__middle_nav_item">
              {
                shoppingCartRule.shoppingCartRule.next_discount === null ? (
                    <p className="text-danger"
                      style={{
                        fontSize: "11px",
                        fontWeight: "700"
                      }}
                    >
                      <Trans i18nKey="Header.4">Hai reggiunto lo sconto massimo consentito</Trans>
                    </p>
                ) : shoppingCartRule.shoppingCartRule.key === "Cart" ? (
                  <p className="text-right">
                    <Trans i18nKey="Header.2">Aggiungi</Trans> <span className="text-danger">{shoppingCartRule.shoppingCartRule.missing_amount ? <CurrencyConversion baseAmount={shoppingCartRule.shoppingCartRule.missing_amount} /> : 0}</span> <Trans i18nKey="Header.5">per poter inviare l'ordine</Trans> <span className="text-danger">{shoppingCartRule.shoppingCartRule.next_discount}</span>
                  </p>
                ) : shoppingCartRule.shoppingCartRule.next_discount === "10.0" ?
                (
                  <p className="text-right">
                    <Trans i18nKey="Header.discount_10%">You got the 5% discount, add </Trans> <span className="text-danger"><b>{shoppingCartRule.shoppingCartRule.missing_amount ? <CurrencyConversion baseAmount={shoppingCartRule.shoppingCartRule.missing_amount} /> : 0}</b></span> <span style={{letterSpacing: "1px"}}><Trans i18nKey="Header.discount_10%_2">to get</Trans></span> <span className="text-danger"><b>{shoppingCartRule.shoppingCartRule.next_discount ? parseFloat(shoppingCartRule.shoppingCartRule.next_discount).toFixed() : 0}% </b></span>
                  </p>
                ) : 
                (
                  <p className="text-right">
                    <Trans i18nKey="Header.2">Aggiungi</Trans> <span className="text-danger"><b>{shoppingCartRule.shoppingCartRule.missing_amount ? <CurrencyConversion baseAmount={shoppingCartRule.shoppingCartRule.missing_amount} /> : 0}</b></span> <span style={{letterSpacing: "1px"}}><Trans i18nKey="Header.3">per il primo livello di sconto extra del 10%</Trans></span> <span className="text-danger"><b>{shoppingCartRule.shoppingCartRule.next_discount ? parseFloat(shoppingCartRule.shoppingCartRule.next_discount).toFixed() : 0}% </b></span><Trans i18nKey="Header.10"></Trans>
                  </p>
                )
              }
            </CHeaderNav> :
            <CHeaderNav className="Header__middle_nav_item">
              {
                shoppingCartRule.shoppingCartRule.missing_amount === "0" ? (
                    <p className="text-danger"
                      style={{
                        fontSize: "11px",
                        fontWeight: "700"
                      }}
                    >
                      <Trans i18nKey="Header.B2btop">Hai raggiunto il minimo d'ordine</Trans>
                    </p>
                ) : shoppingCartRule.shoppingCartRule.missing_amount !== "0" && (
                  <p className="text-right">
                    <Trans i18nKey="Header.2">Aggiungi</Trans> <span className="text-danger">{shoppingCartRule.shoppingCartRule.missing_amount ? <CurrencyConversion baseAmount={shoppingCartRule.shoppingCartRule.missing_amount} /> : 0}</span> <Trans i18nKey="Header.5">per poter inviare l'ordine</Trans> <span className="text-danger">{shoppingCartRule.shoppingCartRule.next_discount}</span>
                  </p>
                ) 
              }
            </CHeaderNav>
          }
        </div>

         <div className="Header__Optional__second">

          <div>
            <CToggler
              inHeader
              className="ml-md-3 d-lg-none"
              onClick={toggleSidebarMobile}
            />
            <CToggler
              inHeader
              className="ml-3 d-md-down-none"
              onClick={toggleSidebar}
            />
          </div>

          <CHeaderBrand className="d-lg-none d-md-none d-xl-none d-sm-none Header__Brand" to="/">
            <img
              src={ITJLogo}
              alt="Italjapan Logo"
              className="Header__Brand__img"
            />
          </CHeaderBrand>

          <CHeaderNav 
            className="mt-2 Header__middle__nav"
            onClick={() => history.push('/carrello')}
            style={{
              cursor: "pointer"
            }}
          >
            <div className="ITJCartButton">
              <div className="ITJCartButton__Widget">
                <Cart />
              </div>
              <div className="ITJCartButton__ProductInfo">
                <h2><CurrencyConversion baseAmount={Number(totalAmount)} /></h2>
                <p>{totalQty} <Trans i18nKey="Header.9">Prodotti</Trans></p>
              </div>
            </div>
          </CHeaderNav>

          <CHeaderNav>
            <TheHeaderDropdown />
          </CHeaderNav>

         </div>
      </div>
  )

  const webHeader = () => (
      <div className="Header__Option">

        <div className="Header__Option__first">
          <div>
            <CToggler
              inHeader
              className="ml-md-3 d-lg-none"
              onClick={toggleSidebarMobile}
            />
            <CToggler
              inHeader
              className="ml-3 d-md-down-none"
              onClick={toggleSidebar}
            />
          </div>
          
          <CHeaderNav 
            className="px-3 Header__Nav"
          >
            <Cerca />
          </CHeaderNav>

        </div>

         <div 
          className="Header__Option__second"
         >

          {
            customerInfo.sales_partner === "B2B BASE" ?
            <CHeaderNav className="Header__middle_item">
              {
                shoppingCartRule.shoppingCartRule.next_discount === null ? (
                    <p className="text-danger text-right">
                      <Trans i18nKey="Header.4">Hai reggiunto lo sconto massimo consentito</Trans>
                    </p>
                ) : shoppingCartRule.shoppingCartRule.key === "Cart" ? (
                  <p className="text-right">
                    <Trans i18nKey="Header.2">Aggiungi</Trans> <span className="text-danger"><b>{shoppingCartRule.shoppingCartRule.missing_amount ? <CurrencyConversion baseAmount={shoppingCartRule.shoppingCartRule.missing_amount} /> : 0}</b></span> <Trans i18nKey="Header.5">per poter inviare l'ordine</Trans> <span className="text-danger"><b>{shoppingCartRule.shoppingCartRule.next_discount}</b></span>
                  </p>
                ) : shoppingCartRule.shoppingCartRule.next_discount === "10.0" ?
                (
                  <p className="text-right">
                    {/* <Trans i18nKey="Header.2">Aggiungi</Trans> <span className="text-danger"><b>{shoppingCartRule.shoppingCartRule.missing_amount ? <CurrencyConversion baseAmount={shoppingCartRule.shoppingCartRule.missing_amount} /> : 0}</b></span> <span style={{letterSpacing: "1px"}}><Trans i18nKey="Header.3">per il primo livello di sconto extra del 10%</Trans></span> <span className="text-danger"><b>{shoppingCartRule.shoppingCartRule.next_discount ? parseFloat(shoppingCartRule.shoppingCartRule.next_discount).toFixed() : 0}10% </b></span><Trans i18nKey="Header.10"></Trans> */}
                    <Trans i18nKey="Header.discount_10%">You got the 5% discount, add </Trans> <span className="text-danger"><b>{shoppingCartRule.shoppingCartRule.missing_amount ? <CurrencyConversion baseAmount={shoppingCartRule.shoppingCartRule.missing_amount} /> : 0}</b></span> <span style={{letterSpacing: "1px"}}><Trans i18nKey="Header.discount_10%_2">to get</Trans></span> <span className="text-danger"><b>{shoppingCartRule.shoppingCartRule.next_discount ? parseFloat(shoppingCartRule.shoppingCartRule.next_discount).toFixed() : 0}% </b></span>
                  </p>
                ) : 
                (
                  <p className="text-right">
                    <Trans i18nKey="Header.2">Aggiungi</Trans> <span className="text-danger"><b>{shoppingCartRule.shoppingCartRule.missing_amount ? <CurrencyConversion baseAmount={shoppingCartRule.shoppingCartRule.missing_amount} /> : 0}</b></span> <span style={{letterSpacing: "1px"}}><Trans i18nKey="Header.3">per il primo livello di sconto extra del 10%</Trans></span> <span className="text-danger"><b>{shoppingCartRule.shoppingCartRule.next_discount ? parseFloat(shoppingCartRule.shoppingCartRule.next_discount).toFixed() : 0}% </b></span><Trans i18nKey="Header.10"></Trans>
                  </p>
                )
              }
              <CProgress 
                animated 
                striped 
                value={
                  shoppingCartRule.shoppingCartRule.key === "Cart" ? 20 :
                  shoppingCartRule.shoppingCartRule.next_discount === "5.0" ? 40 :
                  shoppingCartRule.shoppingCartRule.next_discount === "10.0" ? 80 : 
                  shoppingCartRule.shoppingCartRule.next_discount === null ? 100 : 
                  null
                } 
                className={
                  classNames(
                    "d-md-none d-sm-none d-lg-none d-xl-flex Header__middle__progress",
                    shoppingCartRule.shoppingCartRule.key === "Cart" ? "Header__Cart__key" :
                    shoppingCartRule.shoppingCartRule.next_discount === "5.0" ? "Header__Cart__5" :
                    shoppingCartRule.shoppingCartRule.next_discount === "10.0" ? "Header__Cart__10" : 
                    shoppingCartRule.shoppingCartRule.next_discount === null ? "Header__Cart__null" : 
                    null
                  )
                }
              />
            </CHeaderNav> :
            <CHeaderNav className="Header__middle_item">
              {
                shoppingCartRule.shoppingCartRule.missing_amount === "0" ? (
                    <p className="text-danger text-right">
                      <Trans i18nKey="Header.B2btop">Hai raggiunto il minimo d'ordine</Trans>
                    </p>
                ) : shoppingCartRule.shoppingCartRule.missing_amount !== "0" && (
                  <p className="text-right">
                    <Trans i18nKey="Header.2">Aggiungi</Trans> <span className="text-danger"><b>{shoppingCartRule.shoppingCartRule.missing_amount ? <CurrencyConversion baseAmount={shoppingCartRule.shoppingCartRule.missing_amount} /> : 0}</b></span> <Trans i18nKey="Header.5">per poter inviare l'ordine</Trans> <span className="text-danger"></span>
                  </p>
                )
              }
              <CProgress 
                animated 
                striped 
                value={
                  shoppingCartRule.shoppingCartRule.missing_amount !== "0" ? 20 :
                  shoppingCartRule.shoppingCartRule.missing_amount === "0" ? 100 : 
                  null
                } 
                className={
                  classNames(
                    "d-md-none d-sm-none d-lg-none d-xl-flex Header__middle__progress",
                    shoppingCartRule.shoppingCartRule.missing_amount !== "0" ? "Header__Cart__key" :
                    shoppingCartRule.shoppingCartRule.missing_amount === "0" ? "Header__Cart__null" : 
                    null
                  )
                }
              />
            </CHeaderNav>
          }

          <div className="Header__Option__second__dropdownSection">
            <CHeaderNav 
              className="px-3 mt-2 Header__middle__nav"
              onClick={() => history.push('/carrello')}
              style={{
                cursor: "pointer"
              }}
            >
              <div className="ITJCartButton">
                <div className="ITJCartButton__Widget">
                  <Cart />
                </div>
                <div className="ITJCartButton__ProductInfo">
                  <h2><CurrencyConversion baseAmount={Number(totalAmount)} /></h2>
                  <p>{totalQty} <Trans i18nKey="Header.9">Prodotti</Trans></p>
                </div>
              </div>
            </CHeaderNav>

            <CHeaderNav>
              <TheHeaderDropdown />
            </CHeaderNav>
          </div>

         </div>
      </div>
  )

  return (
    <CHeader withSubheader>
        { 
          width > 600 ? webHeader() : mobileHeader()
        }
      
        <CSubheader className="px-3 justify-content-between">
          <CBreadcrumbRouter
            className="border-0 c-subheader-nav m-0 px-0 px-md-3"
            routes={routes}
          />
        </CSubheader>
    </CHeader>
  );
};

export default TheHeader;
