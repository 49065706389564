import { 
    UPDATE_FIREBASE_WARNING_LOADING,
    SET_FIREBASE_WARNING_LOADING
} from "./types"

export const updateFirebaseWarningState = () => {
    return {
        type: UPDATE_FIREBASE_WARNING_LOADING
    }
}

export const setFirebaseWarningState = () => {
    return {
        type: SET_FIREBASE_WARNING_LOADING
    }
}