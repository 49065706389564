import {
    UPDATE_ORDER_BY_AVAILABILITY,
    UPDATE_ORDER_BY_AVAILABILITY_LOADING
} from './types'
  
const initialState = {
    loading: false,
    availibility: []
}
  
/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function(state = initialState, action) {
    switch (action.type) {
    case UPDATE_ORDER_BY_AVAILABILITY_LOADING:
        return {
            ...state,
            loading: true,
            availibility: []
        }
    case UPDATE_ORDER_BY_AVAILABILITY:
        return {
            ...state,
            loading: false,
            availibility: action.payload        }
    default:
        return state
    }
}