import * as Sentry from "@sentry/react";

const handleClientSideSentry = (name, err, fileName) => {
  Sentry.setContext("function", {
    name,
    fileName,
  });
  Sentry.captureException(err);
};

export default handleClientSideSentry;
