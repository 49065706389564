import {
    GET_ANNOUNCEMENT,
    GET_ANNOUNCEMENT_LOADING
} from './types'

const initialState = {
    loading: false,
    message: {}
}

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function(state = initialState, action) {
    switch (action.type) {
    case GET_ANNOUNCEMENT_LOADING:
        return {
            ...state,
            loading: true,
            message: {}
        }
    case GET_ANNOUNCEMENT:
        return {
            ...state,
            loading: false,
            message: action.payload
        }
    default:
        return state
    }
}