import {
    GET_PAST_ORDER,
    GET_PAST_ORDER_LOADING
} from './types'
  
const initialState = {
    loading: false,
    order: []
}
  
/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function(state = initialState, action) {
    switch (action.type) {
    case GET_PAST_ORDER_LOADING:
        return {
            ...state,
            loading: true,
            order: []
    }
    case GET_PAST_ORDER:
        return {
            ...state,
            loading: false,
            order: action.payload
        }
    default:
        return state
    }
}