import {
    GET_CART_ITEMS,
} from './types'
  
const initialState = {
    cartInfo : []
}

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function(state = initialState, action) {
    switch (action.type) {
        case GET_CART_ITEMS: 
            return {
                ...state,
                cartInfo: action.payload
            }
        default:
            return state
    }
}