import {
    GET_DISCOUNTED_PRICE_INFO,
    GET_DISCOUNTED_PRICE_INFO_LOADING
  } from './types'
  
  const initialState = {
      loading: false,
      discountedPriceInfo: []
  }
  
  /* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
  export default function(state = initialState, action) {
      switch (action.type) {
        case GET_DISCOUNTED_PRICE_INFO_LOADING:
          return {
            ...state,
            loading: true,
            discountedPriceInfo: []
          }
        case GET_DISCOUNTED_PRICE_INFO:
          return {
            ...state,
            loading: false,
            discountedPriceInfo: action.payload
          }
        default:
          return state
      }
    }