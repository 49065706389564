import {
    GET_CUSTOMER_INFO,
    GET_CUSTOMER_INFO_LOADING
} from "./types";

const initialState = {
    loading: false,
    customerInfo : []
}

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function(state = initialState, action)
{
    switch(action.type)
    {
        case GET_CUSTOMER_INFO_LOADING:
            return {
                ...state,
                loading: true,
                customerInfo: []
            }
        case GET_CUSTOMER_INFO:
            return {
                ...state,
                loading: false,
                customerInfo: action.payload
            }
        default:
            return state;
    }
}