import {
    // ErrorBoundary,
    SearchProvider,
    WithSearch,
    SearchBox,
  } from "@elastic/react-search-ui";
  
  import { Layout } from "@elastic/react-search-ui-views";
  import { Search } from "react-bootstrap-icons";
  import { Trans, useTranslation } from "react-i18next";
  
  import React, { useEffect, useState } from "react";
  import applyDisjunctiveFaceting from "src/ES/applyDisjunctiveFaceting";
  import buildRequest from "src/ES/buildRequest";
  import buildState from "src/ES/buildState";
  import runRequest from "src/ES/runRequest";
  import {
    CButton,
    CInput,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
  } from "@coreui/react";
  import { useHistory } from "react-router";
  import { useSelector } from "react-redux";
  import {
    fetchSearchDiscountedPriceInfo,
    // getSearchDiscountedPriceInfo,
  } from "src/feature/erpnextAPI/actions";
  import CurrencyConversion from "src/utils/CurrencyConversion";
  
  const useWindowSize = () => {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    return size;
  };
  
  let total;
  let productItems = [];
  let searchValue = '';
  
  function MobileCerca() {
    const facts = [
      "The first ever watch was a piece of stone.",
      "Wristwatches Were originally made for women. Men started wearing them during and after the first World War.",
      "Watches on a window display usually display ‘happy time’ at ten minutes past ten, which resembles a happy face.",
      "Watches come in many colours, but the most commonly used and gifted watch colour is black.",
      "Despite their beauty and complexity, mechanical watches are generally less accurate than watches with quartz movements."
    ]
  
    const auth = useSelector((state) => state.auth);
    const sidebarItem = useSelector((state) => state.dashboard.dashboardInfo?.sidebar?.children);
  
    const { t } = useTranslation();
    const history = useHistory();
    const [height, width] = useWindowSize();
  
    const [modal, setModal] = useState(false);
  
    const handleModalClick = () => {
      setModal(false);
    };
  
    const onResultClickHandler = (code) => {
      history.push(`/product/${code}`);
    };
  
    const handleMoreResultClick = () => {
      if (searchValue) {
        history.push(`/search/${searchValue}`)
      }
    };
  
    const config = {
      // debug: true,
      hasA11yNotifications: true,
      onAutocomplete: async ({ searchTerm }) => {
        // Check whether these items are allowed to be seen before fetching their discounts
        let sidebarItemGroups = sidebarItem[0]?.children.map((item) => item.name) || [];
        sidebarItemGroups = sidebarItemGroups.concat(sidebarItem[1]?.children.map((item) => item.name) || [])
        sidebarItemGroups = sidebarItemGroups.concat(sidebarItem[2]?.children.map((item) => item.name) || [])
  
        //Sidebar Item Groups
        let sidebarParentItemGroups = sidebarItem[0]?.items || [];
        sidebarParentItemGroups = sidebarParentItemGroups.concat(sidebarItem[1]?.items || [])
        sidebarParentItemGroups = sidebarParentItemGroups.concat(sidebarItem[2]?.items || [])

        searchValue = searchTerm;
        const requestBody = buildRequest({ searchTerm, sidebarItemGroups, sidebarParentItemGroups });
  
        const json = await runRequest(requestBody);
  
        const state = buildState(json);
        total = state.totalResults;
  
        // Update the ES results with Discount and Available Qty from ERPNext
        let esItems = state.results;
        productItems = state.results;
  
        // console.log('es results', state.results)
        
        // Retain only 6 of the returned results.
        esItems = esItems.slice(0, 6);
  
        let esItemGroups = esItems.map(item => item['Item Group']?.raw['Item Group 2']) || [];
        let allItemGroupsLegal = esItemGroups.every((val) => sidebarItemGroups.includes(val))
  
        if (allItemGroupsLegal) {
          let itemCodes = esItems.map((item) => item["Item Code"].raw);
          const data = {
            lead_email: auth.user.email,
            item_codes: JSON.stringify(itemCodes),
          };
          let discountedPriceInfo = await fetchSearchDiscountedPriceInfo(data);
  
          esItems.forEach((esItem) => {
            let discInfo = discountedPriceInfo?.find(
              (item) => item.item_code === esItem["Item Code"].raw
            );
            esItem["Discounted Price"] = {
              raw:
                discInfo?.discounted_price || esItem["Standard Selling Rate"].raw,
            };
            esItem["Available Qty"] = { raw: discInfo?.projected_qty || 0 };
          });
  
          return {
            autocompletedResults: esItems,
          };
        } else {
          return {
            autocompletedResults: []
          }
        }
      },
      onSearch: async (state) => {
        const { resultsPerPage } = state;
        const requestBody = buildRequest(state);
        // Note that this could be optimized by running all of these requests
        // at the same time. Kept simple here for clarity.
        const responseJson = await runRequest(requestBody);
        const responseJsonWithDisjunctiveFacetCounts =
          await applyDisjunctiveFaceting(responseJson, state, [
            "Brand",
            "Item Code",
            "PIM Watch Info.Item Image",
            "Item Image",
          ]);
        return buildState(responseJsonWithDisjunctiveFacetCounts, resultsPerPage);
      },
    };
  
    return (
      <>
        {sidebarItem?.length ? (
          <>
            <CModal
              show={modal}
              onClose={setModal}
              closeOnBackdrop={false}
              color="warning"
            >
              <CModalHeader>
                <CModalTitle>Warning</CModalTitle>
              </CModalHeader>
              <CModalBody>
                <div>
                  <p>Please add some text in the search bar</p>
                  <CButton
                    color="warning"
                    onClick={handleModalClick}
                    style={{
                      width: "5rem",
                    }}
                  >
                    OK
                  </CButton>
                </div>
              </CModalBody>
            </CModal>
  
            <SearchProvider config={config}>
                <SearchBox
                    autocompleteMinimumCharacters={3}
                    inputProps={{ placeholder: t("cerca.1"), className: "form-control"}}
                    autocompleteView={({
                        autocompletedResults,
                        getItemProps,
                    }) => (
                        <div className="cerca__autocomplete">
                            {
                            <p className="mt-1 cerca__autocomplete__productCount">
                                {t("ProductSearch.1")} {total}{" "}
                                {t("ProductSearch.2")}
                            </p>
                            }
                            {
                            sidebarItem.map((item) =>
                                autocompletedResults.map(
                                (result, i) =>
                                    result["Item Group"]?.raw["Item Group 1"] ===
                                    item.name &&
                                    item.children.map(
                                    (ch) =>
                                        result["Item Group"]?.raw[
                                        "Item Group 2"
                                        ] === ch.name && (
                                        <div
                                            {...getItemProps({
                                            key: result["Item Code"].raw,
                                            item: result,
                                            })}
                                            onClick={() =>
                                            onResultClickHandler(
                                                result["Item Code"].raw
                                            )
                                            }
                                        >
                                            <div className="cerca__autocomplete__dropdown">
                                            <img
                                                src={
                                                result["PIM Watch Info"]?.raw[
                                                    "Item Image"
                                                ] ||
                                                result["Item Image"].raw ||
                                                "https://webstockreview.net/images/see-clipart-wrist-watch.jpg"
                                                }
                                                alt="Product-ImageUrl"
                                                className="cerca__autocomplete__dropdown__image"
                                            />
                                            <div className="cerca__autocomplete__dropdown__items">
                                                <span className="cerca__autocomplete__dropdown__items__title">
                                                {/* {t("ProductSearch.6")}{" "} */}
                                                {result["Item Code"].raw}
                                                </span>
                                                <span>
                                                {t("ProductSearch.3")}:
                                                <span className="cerca__autocomplete__dropdown__items__sellingRate">
                                                    {" "}
                                                    <CurrencyConversion
                                                    baseAmount={
                                                        result[
                                                        "Standard Selling Rate"
                                                        ].raw
                                                    }
                                                    />
                                                </span>
                                                </span>
                                                <span>
                                                {t("ProductSearch.4")}:
                                                <span className="cerca__autocomplete__dropdown__items__discount">
                                                    {" "}
                                                    <CurrencyConversion
                                                    baseAmount={
                                                        result["Discounted Price"]
                                                        .raw
                                                    }
                                                    />
                                                </span>
                                                </span>
                                                {result["Available Qty"]?.raw ? (
                                                <span
                                                    className="text-success"
                                                    style={{
                                                    fontWeight: "bold",
                                                    fontSize: "12px"
                                                    }}
                                                >
                                                    <span>
                                                    {
                                                        result["Available Qty"]
                                                        ?.raw
                                                    }
                                                    </span>{" "}
                                                    <Trans i18nKey="Categoria.8">
                                                    Prodotti disponibili
                                                    </Trans>
                                                </span>
                                                ) : (
                                                <span
                                                    style={{
                                                    fontWeight: "bolder"
                                                    }}
                                                    className="text-danger"
                                                >
                                                    <Trans i18nKey="Categoria.12">
                                                    Out of stock
                                                    </Trans>
                                                </span>
                                                )}
                                            </div>
                                            </div>
                                        </div>
                                        )
                                    )
                                )
                            )
                            }
                            <div className="d-flex justify-content-center">
                            <CButton
                                className="mt-3 mb-1"
                                color="primary"
                                onClick={handleMoreResultClick}
                            >
                                {t("ProductSearch.5")}
                            </CButton>
                            </div>
                        </div>
                    )}
                    onSubmit={(searchTerm) => {
                    searchTerm && searchTerm.length >= 3
                        ? history.push(`/search/${searchTerm}`)
                        : setModal(true);
                    }}
                    autocompleteResults={true}
                    autocompleteSuggestions={true}
                />
            </SearchProvider>
          </>
        ) : (
          <>
            {facts[Math.floor(Math.random() * 5)]}
          </>
        )}
      </>
    );
  }
  
  export default MobileCerca;
  