import {
    SET_CHECKOUT_ORDER_LOADING,
    SET_CHECKOUT_ORDER,
    REMOVE_CHECKOUT_ORDER_DETAILS
} from "./types";

const initialState = {
    loading: false,
    checkoutInfo : {}
}

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function(state = initialState, action)
{
    switch(action.type)
    {
        case SET_CHECKOUT_ORDER_LOADING:
            return {
                ...state,
                loading: true,
                checkoutInfo: {}
            }
        case SET_CHECKOUT_ORDER:
            return {
                ...state,
                loading: false,
                checkoutInfo: action.payload
            }
        case REMOVE_CHECKOUT_ORDER_DETAILS:
            return {
                ...state,
                loading: false,
                checkoutInfo: {}
            }
        default:
            return state;
    }
}