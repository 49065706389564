import {combineReducers} from "redux";

import authReducer from './auth/reducer'
import errorReducer from './error/reducer'
import timeoutErrorReducer from './timeoutError/reducer'
import firebaseWarningReducer from './firebaseWarning/reducer'
import darkModeReducer from './darkMode/reducer'
import sidebarReducer from './sidebar/reducer'
import asidebarReducer from './asidebar/reducer'
import languageReducer from './language/reducer'
import shoppingCartRuleReducer from './erpnextAPI/reducer'
import StaticWebPageReducer from './erpnextAPI/StaticWebPage/reducer'
import DashboardInfoReducer from './erpnextAPI/DashboardInfoAPI/reducer'
import SlidebarItemReducer from './erpnextAPI/SlidebarApi/reducer'
import productInfoReducer from './erpnextAPI/ProductInfo/reducer'
import discountedPriceInfoReducer from './erpnextAPI/DiscountedPriceAPI/reducer'
import searchDiscountedInfoReducer from './erpnextAPI/SearchDiscountedPriceAPI/reducer'
import cartReducer from './cart/reducer'
import countryListReducer from './erpnextAPI/GetCountryAPI/reducer'
import customerReducer from './erpnextAPI/CustomerInfoAPI/reducer'
import checkoutOrderReducer from './erpnextAPI/CheckoutAPI/reducer'
import updateCartReducer from './erpnextAPI/UpdateCartAPI/reducer'
import newArrivalsReducer from './erpnextAPI/NewArrivalsAPI/reducer'
import updateOrderByAvailibilityReducer from './erpnextAPI/UpdateOrderByAvailability/reducer'
import currencyReducer from './currencySelector/reducer'
import activeOrderReducer from './erpnextAPI/ActiveOrderAPI/reducer'
import orderPastReducer from './erpnextAPI/OrderAPI/reducer'
import orderPastDetailsReducer from './erpnextAPI/OrderDetailsAPI/reducer'
import updateCustomerProfileReducer from './erpnextAPI/UpdateProfile/reducer'
import listOfOuteletItemsReducer from './erpnextAPI/ListOfOutelet/reducer'
import AnnouncementReducer from './erpnextAPI/AnnouncementAPI/reducer'
import MaintenanceReducer from './erpnextAPI/MaintenanceAPI/reducer'
import campaignReducer from './campaign/reducer'

export default combineReducers({
    auth: authReducer,
    campaign: campaignReducer,
    error: errorReducer,
    firebaseError: firebaseWarningReducer,
    timeoutError: timeoutErrorReducer,
    sidebarShow: sidebarReducer,
    asideShow: asidebarReducer,
    darkMode: darkModeReducer,
    language: languageReducer,
    shoppingCartRule: shoppingCartRuleReducer,
    staticWebsite: StaticWebPageReducer,
    dashboard: DashboardInfoReducer,
    sidebarItem: SlidebarItemReducer,
    productInfo: productInfoReducer,
    discountedPrice: discountedPriceInfoReducer,
    cart: cartReducer,
    country: countryListReducer,
    checkoutCustomerInfo: customerReducer,
    checkoutOrder: checkoutOrderReducer,
    updateCart: updateCartReducer,
    newArrivals: newArrivalsReducer,
    currencySelector: currencyReducer,
    orderAvailibility: updateOrderByAvailibilityReducer,
    activeOrder: activeOrderReducer,
    pastOrder: orderPastReducer,
    pastOrderDetails: orderPastDetailsReducer,
    searchDiscountedInfo: searchDiscountedInfoReducer,
    updateProfile: updateCustomerProfileReducer,
    outletList: listOfOuteletItemsReducer,
    announcementNotifications: AnnouncementReducer,
    maintenance: MaintenanceReducer,
})