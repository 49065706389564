import CIcon from "@coreui/icons-react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { settingLanguage } from "src/feature/language/actions";

function LanguageSwitcher({ updateRtdbLanguage }) {
  const [currentLang, setCurrentLang] = useState(1);
  const { i18n } = useTranslation();

  const language = useSelector((state) => state.language.language);
  const dispatch = useDispatch();
  const setLanguage = (state) => dispatch(settingLanguage(state));

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
    // If the function exists
    if (updateRtdbLanguage) updateRtdbLanguage(lng);
  };
  const flagWidth = "28";
  const classNameReturner = (num) => {
    return num === currentLang
      ? "languageSwitchbar__block languageSwitchbar__block--selected"
      : "languageSwitchbar__block";
  };

  useEffect(() => {
    if (language === "en") setCurrentLang(0);
    if (language === "it") setCurrentLang(1);
    if (language === "es") setCurrentLang(2);
    if (language === "fr") setCurrentLang(3);
  }, [dispatch, language]);

  return (
    <div className="languageSwitchbar">
      <div
        onClick={() => {
          changeLanguage("en");
          // setCurrentLang(0);
        }}
        id="englishFlag"
        className={classNameReturner(0)}
      >
        <CIcon name="cif-gb" alt="ENG Flag" width={flagWidth} />
      </div>
      <div
        onClick={() => {
          changeLanguage("it");
          // setCurrentLang(1);
        }}
        id="italianFlag"
        className={classNameReturner(1)}
      >
        <CIcon name="cif-it" alt="ENG Flag" width={flagWidth} />
      </div>
      <div
        onClick={() => {
          changeLanguage("es");
          // setCurrentLang(2);
        }}
        id="spanishFlag"
        className={classNameReturner(2)}
      >
        <CIcon name="cif-es" alt="ENG Flag" width={flagWidth} />
      </div>
      <div
        onClick={() => {
          changeLanguage("fr");
          // setCurrentLang(3);
        }}
        id="frenchFlag"
        className={classNameReturner(3)}
      >
        <CIcon name="cif-fr" alt="ENG Flag" width={flagWidth} />
      </div>
    </div>
  );

  // switch (language) {
  //   case "it":
  //     return (
  //       <CIcon
  //         name="cif-gb"
  //         alt="ENG Flag"
  //         width={15}
  //         onClick={() => changeLanguage("en")}
  //         style={{
  //           marginRight: '10px',
  //           width: '1.2rem',
  //         }}
  //       />
  //     );
  //   case "en":
  //     return (
  //       <CIcon
  //         name="cif-it"
  //         alt="ITA Flag"
  //         width={15}
  //         onClick={() => changeLanguage("it")}
  //         style={{
  //           marginRight: '10px',
  //           width: '1.2rem',
  //         }}
  //       />
  //     );
  //   default:
  //     return (<CIcon
  //       name="cif-gb"
  //       alt="ENG Flag"
  //       width={15}
  //       onClick={() => changeLanguage("en")}
  //       style={{
  //         marginRight: '10px',
  //         width: '1.2rem',
  //       }}
  //     />);
  // }
}

export default LanguageSwitcher;
