import { SET_LANGUAGE } from "./types";

export const settingLanguage = (state) => (dispatch, getState) => {
  dispatch({
    type: SET_LANGUAGE,
    payload: state,
  });
  // Removing local storage dependency
  // localStorage.setItem('language', JSON.stringify(getState().language.language));
};
