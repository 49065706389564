import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { AuthProvider } from "./common/AuthProvider";
import RoleBasedPrivateRoutes from "./common/RoleBasedPrivateRoutes";
import { ErrorBoundary } from "./common/ErrorBoundary";
import { useDispatch } from "react-redux";
import { registerCampaign } from "src/feature/campaign/actions";

// import ReactPixel from 'react-facebook-pixel';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const ForgotPassword = React.lazy(() =>
  import("./views/pages/login/ForgotPassword")
);

const query = new URLSearchParams(window.location.search);

// #193: Extract the campaign from the incoming query.
const _campaign = query.get("utm_campaign");

Sentry.init({
  dsn: "https://226d4fc4c8f84e83871d6404a19a2441@o485053.ingest.sentry.io/5539103",
  integrations: [new Integrations.BrowserTracing()],
  ignoreErrors: [
    "Non-Error exception captured",
    "Non-Error promise rejection captured",
  ],

  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        lang: "en",
        title:
          "Sorry...there is a bug. I already sent a message to the support team",
        subtitle:
          "Please send your feedback to help us solve the problem soon as possible!",
        subtitle2: "Thanks!",
      });
    }
    return event;
  },

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

// ReactPixel.init('664841791291362', {}, options);

export default function App() {
  const dispatch = useDispatch();

  // #193: Register the campaign in the memory and application.
  useEffect(() => {
    if (_campaign !== null) {
      dispatch(registerCampaign(_campaign.toLowerCase()));
    }
  }, [window.location.search.length > 0]);

  // Moved the AuthProvider into React.Suspense because when authProvide
  // is the Wrapper, useTranslation in AuthProvider throws error without
  // description.
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <AuthProvider>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/forgot_password" component={ForgotPassword} />
              <RoleBasedPrivateRoutes />
            </Switch>
          </AuthProvider>
        </React.Suspense>
      </BrowserRouter>
    </ErrorBoundary>
  );
}
