import {
    GET_STATIC_WEB_PAGE,
    GET_STATIC_WEB_PAGE_LOADING
} from "./types";

const initialState = {
    loading: false,
    staticPage : {}
}

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function(state = initialState, action)
{
    switch(action.type)
    {
        case GET_STATIC_WEB_PAGE_LOADING:
            return {
                ...state,
                loading: true,
                staticPage: {}
            }
        case GET_STATIC_WEB_PAGE:
            return {
                ...state,
                loading: false,
                staticPage: action.payload
            }
        default:
            return state;
    }
}