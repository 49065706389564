import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { auth } from "./common/Firebase";
import { icons } from "./assets/icons";

import { Provider } from "react-redux";
import store from "./store";
import { setCurrentUser } from "./feature/auth/actions";

import "src/common/i18n";

React.icons = icons;

// if(localStorage.loginCred)
// {
//   //Checking if data present in localstorage then login persist
//   const data = localStorage.loginCred;
//   const decoded = JSON.parse(data)
//   store.dispatch(setCurrentUser(decoded));
// }

if (!window.console) window.console = {};
var methods = ["warn"];
methods.forEach((item) => {
  console[item] = function () {};
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
