import {
  SET_SIDEBAR
} from './types'

const initialState = {
    sidebarShow: 'responsive',
}

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function(state = initialState, action) {
    switch (action.type) {
      case SET_SIDEBAR:
        return {
          ...state,
          sidebarShow: action.payload
        }
      default:
        return state
    }
  }