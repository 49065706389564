import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./feature/rootreducer";
import { database } from "src/common/Firebase";
import { lang, currency } from "./common/defaults";
const darkModeState = localStorage.getItem("darkMode")
  ? JSON.parse(localStorage.getItem("darkMode"))
  : false;
const auth =
  localStorage.getItem("loginCred") &&
  JSON.parse(localStorage.getItem("loginCred"));
const languageState = lang;
const sidebarShowState = localStorage.getItem("sidebarShow")
  ? JSON.parse(localStorage.getItem("sidebarShow"))
  : "responsive";
const campaignState = localStorage.getItem("_campaign")
  ? JSON.parse(localStorage.getItem("_campaign"))
  : "";
const globalCurrency = currency;
let cartItems = [];

// The below code is commented because it is asyncronous and cart items will always
// be empty if the below code is used to populate the cart.
// if (auth) {
//   database.ref(`cart/${auth.uid}`).on("value", (snapshot) => {
//     const vals = snapshot.val();
//     for (var key in vals) {
//       cartItems.push({
//         ...vals[key],
//         id: key,
//       });
//     }
//   });
// }

// console.log("_records", cartItems)

const initialState = {
  cart: {
    cartInfo: cartItems,
  },
  darkMode: {
    darkMode: darkModeState,
  },
  language: {
    language: languageState,
  },
  sidebarShow: {
    sidebarShow: sidebarShowState,
  },
  currencySelector: {
    globalCurrency: globalCurrency,
  },
  campaign: {
    campaignData: campaignState,
  },
};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleware)
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export default store;
