import {
    GET_PRODUCT_PAGE_INFO,
    GET_PRODUCT_PAGE_INFO_LOADING
} from "./types";

const initialState = {
    loading: false,
    productDesc : {}
}

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function(state = initialState, action)
{
    switch(action.type)
    {
        case GET_PRODUCT_PAGE_INFO_LOADING:
            return {
                ...state,
                loading: true,
                productDesc: {}
            }
        case GET_PRODUCT_PAGE_INFO:
            return {
                ...state,
                loading: false,
                productDesc: action.payload
            }
        default:
            return state;
    }
}