import React from 'react';

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));
const PaginaCategoria = React.lazy(() => import('./views/PaginaCategoria/index'));
const ForgotPassword = React.lazy(() => import('./views/pages/login/ForgotPassword'));
const Product = React.lazy(() => import('./views/Product/index'));
const AddToCart = React.lazy(() => import('./views/addToCart/index'));
const Checkout = React.lazy(() => import('./views/checkout/index'));
const order = React.lazy(() => import('./views/order/Order/index'));
const PaginaOrder = React.lazy(() => import('./views/order/paginaOrder/index'));
const AccountDetails = React.lazy(() => import('./views/AccountDetails/index'));
const Search = React.lazy(() => import('./views/Search/index'));

const routes = [
  { path: '/', exact: true, name: 'Home'},
  { path: '/dashboard', exact: true, component: Dashboard},
  { path: '/Jewellery', exact: true, name: 'Jewellery', component: PaginaCategoria},
  { path: '/Jewellery/:name', exact: true, name: 'Brand', component: PaginaCategoria},
  { path: '/Jewellery/pgno/:pageNumber', exact: true, component: PaginaCategoria},
  { path: '/Jewellery/:name/page/:pageNumber', exact: true, component: PaginaCategoria},
  { path: '/Jewellery/:name/:child', exact: true, component: PaginaCategoria},
  { path: '/Jewellery/:name/:child/page/:pageNumber', exact: true, component: PaginaCategoria},
  { path: '/Clocks', exact: true, name: 'Clocks', component: PaginaCategoria},
  { path: '/Clocks/:name', exact: true, name: 'Brand', component: PaginaCategoria},
  { path: '/Clocks/pgno/:pageNumber', exact: true, component: PaginaCategoria},
  { path: '/Clocks/:name/page/:pageNumber', exact: true, component: PaginaCategoria},
  { path: '/Clocks/:name/:child', exact: true, component: PaginaCategoria},
  { path: '/Clocks/:name/:child/page/:pageNumber', exact: true, component: PaginaCategoria},
  { path: '/product/:code', exact: true, name: 'Product', component: Product},
  { path: '/Watches', exact: true, name: 'Watches', component: PaginaCategoria},
  { path: '/Watches/pgno/:pageNumber', exact: true, component: PaginaCategoria},
  { path: '/Watches/:name/', exact: true, name: 'Brand', component: PaginaCategoria},
  { path: '/Watches/:name/page/:pageNumber', exact: true, component: PaginaCategoria},
  { path: '/Watches/:name/:child', exact: true, component: PaginaCategoria},
  { path: '/Watches/:name/:child/page/:pageNumber', exact: true, component: PaginaCategoria},
  { path: '/Watch Batteries', exact: true, name: 'Watch Batteries', component: PaginaCategoria},
  { path: '/Watch Batteries/pgno/:pageNumber', exact: true, component: PaginaCategoria},
  { path: '/Watch Batteries/:name/', exact: true, name: 'Brand', component: PaginaCategoria},
  { path: '/Watch Batteries/:name/page/:pageNumber', exact: true, component: PaginaCategoria},
  { path: '/Watch Batteries/:name/:child', exact: true, component: PaginaCategoria},
  { path: '/Watch Batteries/:name/:child/page/:pageNumber', exact: true, component: PaginaCategoria},
  { path: '/Leather', exact: true, name: 'Leather', component: PaginaCategoria},
  { path: '/Leather/pgno/:pageNumber', exact: true, component: PaginaCategoria},
  { path: '/Leather/:name/', exact: true, name: 'Brand', component: PaginaCategoria},
  { path: '/Leather/:name/page/:pageNumber', exact: true, component: PaginaCategoria},
  { path: '/Leather/:name/:child', exact: true, component: PaginaCategoria},
  { path: '/Leather/:name/:child/page/:pageNumber', exact: true, component: PaginaCategoria},
  { path: '/carrello', exact: true, name: 'Carrello', component: AddToCart},
  { path: '/cassa', exact: true, name: 'Cassa', component: Checkout},
  { path: '/forgot_password', exact: true, name: 'Forgot Password', component: ForgotPassword},
  { path: '/order', exact: true, name: 'Order', component: order},
  { path: '/pagina_order/:item_name', exact: true, name: 'Pagina Order', component: PaginaOrder},
  { path: '/profile', exact: true, name: 'Profile', component: AccountDetails},
  { path: '/search/:searchTerm', exact: true, component: Search},
  { path: '/search/:searchTerm/page/:pageNumber', exact: true, component: Search},
]

export default routes;
