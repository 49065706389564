import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Switch, useLocation } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import classNames from "classnames";
import { TheFooter, TheHeader, TheSidebar } from "src/containers";
import SplashScreen from "src/views/Loading/SplashScreen/index";
import ErrModule from "src/utils/ErrModule";
import {
  getAnnouncementText,
  getCountryList,
  getMaintenanceMode,
} from "src/feature/erpnextAPI/actions";
import { setCurrencySelector } from "src/feature/currencySelector/actions";
import ErrRegistrationModule from "src/utils/ErrRegistrationModule";
import IdelTimerContainer from "src/utils/IdelTimeContainer";
import NotificationBand from "src/utils/NotificationBand";
import Maintenance from "src/utils/Maintenance";
import FirebaseWarningModal from "src/utils/FirebaseWarningModal";
import { useContext } from "react";
import { AuthContext } from "./AuthProvider";

export default function RoleBasedPrivateRoutes() {
  const TheLayout = React.lazy(() => import("../containers/TheLayout"));
  const ITJSpinner = React.lazy(() => import("src/reusable/ITJSpinner"));
  const { firebaseLoading } = useContext(AuthContext);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const error = useSelector((state) => state.error);
  const sidebarLoading = useSelector((state) => state.dashboard.loading);
  const location = useLocation();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const maintenance = useSelector((state) => state.maintenance);
  const announcementNotifications = useSelector(
    (state) => state.announcementNotifications.message.announcement_text
  );
  const classes = classNames(
    "c-app c-default-layout",
    darkMode && "c-dark-theme"
  );

  //Append Zendesk CDN when user authenticated or else remove the CDN
  // useEffect(() => {
  //   let head = document.getElementsByTagName("head")[0];
  //   if (auth.isAuth) {
  //     const script = document.createElement("script");

  //     script.id = "ze-snippet";
  //     script.src =
  //       "https://static.zdassets.com/ekr/snippet.js?key=69b45b71-49f4-427b-9469-7abebcf98cfb";

  //     head.appendChild(script);
  //   } else {
  //     let scripts = head.getElementsByTagName("script");
  //     for (let i = 0; i < scripts.length; i++) {
  //       if (scripts[i].id === "ze-snippet") {
  //         head.removeChild(scripts[i]);
  //       }
  //     }
  //   }
  // }, [auth]);

  useEffect(() => {
    if (auth.user.email) {
      dispatch(getCountryList());
      dispatch(getAnnouncementText());
      dispatch(setCurrencySelector());
      // const outletData = {
      //   lead_email: auth.user.email
      // }
      //dispatch(getListOfOuteltItems(outletData))
    }
  }, [dispatch, auth]);

  // MaintenanceAPI
  useEffect(() => {
    dispatch(getMaintenanceMode());
  }, [dispatch]);

  if (Object.keys(error || {}).length > 0) {
    return <ErrModule />;
  } else if (auth.isAuth) {
    return (
      <div className={classes}>
        {maintenance &&
        maintenance.mode &&
        maintenance.mode === true &&
        !maintenance.maintenance_users?.includes(auth.user.email) ? (
          <Maintenance />
        ) : (
          <>
            <TheSidebar />
            {sidebarLoading && !firebaseLoading ? (
              <SplashScreen />
            ) : (
              <div className="c-wrapper">
                <TheHeader />
                {announcementNotifications !== null && <NotificationBand />}
                <div className="c-body">
                  <ErrModule />
                  <FirebaseWarningModal />
                  <IdelTimerContainer />
                  <React.Suspense
                    fallback={
                      <h3
                        style={{
                          padding: "1.5rem 0",
                          display: "block",
                          textAlign: "center",
                        }}
                      >
                        <ITJSpinner />
                      </h3>
                    }
                  >
                    <Switch>
                      <PrivateRoute
                        exact
                        path="/dashboard"
                        component={TheLayout}
                      />
                      <PrivateRoute exact path="/order" component={TheLayout} />
                      <PrivateRoute
                        exact
                        path="/pagina_order/:item_name"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/profile"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/carrello"
                        component={TheLayout}
                      />
                      <PrivateRoute exact path="/cassa" component={TheLayout} />
                      <PrivateRoute
                        exact
                        path="/Clocks"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Clocks/:name"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Clocks/pgno/:pageNumber"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Clocks/:name/page/:pageNumber"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Clocks/:name/:child"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Clocks/:name/:child/page/:pageNumber"
                        component={TheLayout}
                      />
                          <PrivateRoute
                        exact
                        path="/Watch Batteries"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Watch Batteries/:name"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Watch Batteries/pgno/:pageNumber"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Watch Batteries/:name/page/:pageNumber"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Watch Batteries/:name/:child"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Watch Batteries/:name/:child/page/:pageNumber"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Jewellery"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Jewellery/:name"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Jewellery/pgno/:pageNumber"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Jewellery/:name/page/:pageNumber"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Jewellery/:name/:child"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Jewellery/:name/:child/page/:pageNumber"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Watches"
                        component={TheLayout}
                      />
                      {/* <PrivateRoute exact path="/Watches/pgno/:pageNumber" component={TheLayout} /> */}
                      <PrivateRoute
                        exact
                        path="/Watches/:name"
                        component={TheLayout}
                      />
                      {/* <PrivateRoute exact path="/Watches/:name/page/:pageNumber" component={TheLayout} /> */}
                      <PrivateRoute
                        exact
                        path="/product/:code"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Watches/:name/:child"
                        component={TheLayout}
                      />
                      {/* <PrivateRoute exact path="/Watches/:name/:child/page/:pageNumber" component={TheLayout} /> */}
                      <PrivateRoute
                        exact
                        path="/search/:searchTerm"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/search/:searchTerm/page/:pageNumber"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Leather"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Leather/:name"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Leather/pgno/:pageNumber"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Leather/:name/page/:pageNumber"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Leather/:name/:child"
                        component={TheLayout}
                      />
                      <PrivateRoute
                        exact
                        path="/Leather/:name/:child/page/:pageNumber"
                        component={TheLayout}
                      />
                    </Switch>
                  </React.Suspense>
                </div>
                <TheFooter />
              </div>
            )}
          </>
        )}
      </div>
    );
  } else {
    if (!(location.pathname === "/forgot_password")) {
      return <Redirect to={"/"} />;
    }
    return null;
  }
}
