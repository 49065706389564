import { Trans, useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { auth } from "./Firebase";
import * as Sentry from "@sentry/react";
import { setCurrentUser as setCurrentUserInRedux } from "../feature/auth/actions";
import store from "../store";
import rtdbStateSync from "src/reusable/rtdbStateSync";
import { useDispatch } from "react-redux";
import { setGlobalCurrency } from "src/feature/currencySelector/actions";
import SplashScreen from "src/views/Loading/SplashScreen/index";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [currentUser, setCurrentUser] = useState(null);
  const [firebaseLoading, setFirebaseLoading] = useState(true);
  const [routeBeforeLogin, setRouteBeforeLogin] = useState("/");
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        //Set sentry user data
        Sentry.configureScope(function (scope) {
          scope.setUser({
            id: user.uid,
            email: user.email,
          });
        });
        const data = {
          email: user.email,
          uid: user.uid,
        };
        //Authentication completion is subject to users record being on rtdb
        const completeAuthentication = () => {
          store.dispatch(setCurrentUserInRedux(data));
          //Completion of this is also directly connected to all the routes rendering in roleBasedPrivateRoutes
          setCurrentUser(user);
          setFirebaseLoading(false);
        };
        rtdbStateSync(
          user.uid,
          dispatch,
          i18n,
          setGlobalCurrency,
          completeAuthentication
        );
      } else {
        //Remembering the last route the user was trying to goto
        //before we push him to the login page.
        setRouteBeforeLogin(window.location.pathname + window.location.search);
        setFirebaseLoading(false);
        setCurrentUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      Sentry.setContext("user_info", {
        lead_email: currentUser.email,
      });

      Sentry.setTag("ITJB2B.user.email", currentUser.email);
    }
  }, [currentUser]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        firebaseLoading,
        setFirebaseLoading,
        routeBeforeLogin,
      }}
    >
      {/* Do not load children until firebase rtdbstateSync has completed as */}
      {/* the children(roleBasedPrivateRoutes) has routes which depend on the user auth being true once the user has logged in */}
      {/* Hence wait for the setCurrentUser() to run  only then will it cause proper rendering of all routes.*/}
      {/* If firebaseLoading is not waited for, then every route will point to "/".  */}
      {firebaseLoading ? <SplashScreen /> : children}
    </AuthContext.Provider>
  );
};
