import axios from "axios";
import * as Sentry from "@sentry/react";

import { GET_ERROR } from "../error/types";

import {
  SET_CURRENCY,
  SET_CURRENCY_LOADING,
  SET_GLOBAL_CURRENCY,
} from "./types";

export const setCurrencySelector = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_CURRENCY_LOADING,
    });

    const res = await axios.get(`https://api.frankfurter.app/latest?from=EUR`);

    dispatch({
      type: SET_CURRENCY,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
    Sentry.captureException(error);
  }
};

export const setGlobalCurrency = (data) => (dispatch, getState) => {
  dispatch({
    type: SET_GLOBAL_CURRENCY,
    payload: data,
  });
  // Removing localhost dependency
  // localStorage.setItem('globalCurrency', JSON.stringify(getState().currencySelector.globalCurrency));
};
