import {
    SET_SIDEBAR
} from './types'

export const setSideBar = (val) => (dispatch,getState) => {
    dispatch({
        type: SET_SIDEBAR,
        payload: val
    })

    localStorage.setItem('sidebarShow', JSON.stringify(getState().sidebarShow.sidebarShow));

}