import {
    SET_FIREBASE_WARNING_LOADING,
    UPDATE_FIREBASE_WARNING_LOADING
} from "./types";

const initialState = {
    firebaseWarning: false,
}

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function(state = initialState, action)
{
    switch(action.type)
    {
        case SET_FIREBASE_WARNING_LOADING:
            return {
                ...state,
                firebaseWarning: true,
            };
        case UPDATE_FIREBASE_WARNING_LOADING:
            return {
                ...state,
                firebaseWarning: false,
            };
        default:
            return state;
    }
}