import { SET_CURRENT_USER, SET_CURRENT_USER_OFF } from "./types";

export const setLogin = (data) => (dispatch) => {
  const decoded = JSON.stringify(data);
  //   Removing local storage dependency
  //   localStorage.setItem("loginCred", decoded);
  dispatch(setCurrentUser(data));
};

export const setCurrentUser = (data) => {
  return {
    type: SET_CURRENT_USER,
    payload: data,
  };
};

export const setLogout = () => (dispatch) => {
  localStorage.removeItem("loginCred");
  dispatch(setCurrentUserOff());
};

export const setCurrentUserOff = () => {
  return {
    type: SET_CURRENT_USER_OFF,
  };
};
