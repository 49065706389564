import React from 'react'
import './styles.css'
import { CSpinner } from '@coreui/react'
import { Trans } from "react-i18next";

const Index = () => {
    // const { t } = useTranslation();

    return (
        <div className="ITJSplashScreen">
            <img
                src="/logo_itj.png"
                alt="Italjapan Logo"
                className="ITJSplashScreen__Logo"
            />
            <br/>
            <div className="ITJSplashScreen__Content">
                <h4 className="mb-0 text-muted">
                    <Trans i18nKey="splashScreen.1">
                        Initializing
                    </Trans>
                </h4>
                <CSpinner size="sm" variant="grow" />
            </div>
        </div>
    )
}

export default Index
