import {
  SET_DARK_MODE
} from './types'

const initialState = {
    darkMode: false
}

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function(state = initialState, action) {
    switch (action.type) {
      case SET_DARK_MODE:
        return {
          ...state,
          darkMode: action.payload
        }
      default:
        return state
    }
  }