import {
    SET_CURRENCY_LOADING,
    SET_CURRENCY,
    SET_GLOBAL_CURRENCY
} from './types'

const initialState = {
    loading: false,
    currency: {},
    globalCurrency: {
        name: "EUR"
    },
}

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function(state = initialState, action) {
    switch (action.type) {
    case SET_CURRENCY_LOADING:
        return {
            ...state,
            loading: true,
            currency: {}
        }
    case SET_CURRENCY:
        return {
            ...state,
            loading: false,
            currency: action.payload
        }
    case SET_GLOBAL_CURRENCY:
        return {
            ...state,
            globalCurrency: {
                name: action.payload.name, 
                value: action.payload.value
            }
        }
    default:
        return state
    }
}