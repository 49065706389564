import React from 'react'
import { useSelector } from 'react-redux';

const CurrencyConversion = ({baseAmount}) => {

    const selectedCurrency = useSelector(state => state.currencySelector);

    const {
        globalCurrency,
        currency
    } = selectedCurrency

    const afterConversion = globalCurrency?.name !== "EUR" ? parseFloat(baseAmount * globalCurrency?.value).toFixed(2) : baseAmount * currency?.amount

    const locals = navigator.language

    return (
        <>
            {
                Intl.NumberFormat(locals, { style: 'currency', currency: globalCurrency.name }).format(
                    afterConversion
                )
            }
        </>
    )
}

export default CurrencyConversion