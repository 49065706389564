import {
    GET_LIST_OF_OUTLET_ITEMS,
    GET_LIST_OF_OUTLET_ITEMS_LOADING
} from './types'

const initialState = {
    loading: false,
    listOfItems: []
}

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function(state = initialState, action) {
    switch (action.type) {
    case GET_LIST_OF_OUTLET_ITEMS_LOADING:
        return {
            ...state,
            loading: true,
            listOfItems: []
        }
    case GET_LIST_OF_OUTLET_ITEMS:
        return {
            ...state,
            loading: false,
            listOfItems: action.payload
        }
    default:
        return state
    }
}